import { Component, Vue } from 'vue-facing-decorator'
import { FormGroup, FormSection, TextField, TextareaField, SelectField, FormValidation } from '@/components/Forms'
import ExamView from '@/components/Exams/ExamView.vue'
import Loading from '@/components/Loading.vue'
import QuestionTemplatesList from '@/components/Jobs/QuestionTemplatesList.vue'
import { List, type IListOptions } from '@/components/Lists'
import ProgressModal from '@/components/ProgressModal.vue'
import type { CMS, Study } from '@pocketprep/types'
import { objPointer } from '@/store/ParseUtils'
import { activitiesModule } from '@/store/activities/module'
import type { TQuestionType } from '@/store/types'
import type { TEnhancedQuestion } from '@/store/questions/types'
import mockExamDraftsModule from '@/store/mockExamDrafts/module'
import examsModule from '@/store/exams/module'
import examDraftsModule from '@/store/examDrafts/module'
import questionDraftsModule from '@/store/questionDrafts/module'
import questionsModule from '@/store/questions/module'
import kaDraftsModule from '@/store/knowledgeAreaDrafts/module'
import TitleText from '@/components/TitleText.vue'
import ButtonFooter from '@/components/ButtonFooter.vue'
import UIKit from '@pocketprep/ui-kit'
import { bloomTaxonomyLevels, isKeywordGenerationEnabled, stripCKEditorTags } from '@/utils'
import questionScenarioDraftsModule from '@/store/questionScenarioDrafts/module'
import * as Sentry from '@sentry/browser'
import questionScenariosModule from '@/store/questionScenarios/module'

interface IVersionInfo {
    type: 'Major' | 'Minor' | 'Patch'
    description: string
}

interface IQuestionDraftRow {
    objectId: string
    serial: string
    knowledgeAreaDraft: string
    prompt: string
    type: TQuestionType
    draftType: 'New' | 'Updated' | 'Existing'
    isArchived: 'Yes' | 'No'
    isFree: 'Yes' | 'No'
    appName: string
    subCategory: string
    answers: string[]
    explanation: string
    passage: string
    references: string[]
    distractors: string[]
    dateAdded?: string
    images?: { 
        explanation?: {
            url: string
            altText: string
            longAltText?: string
        }
        passage?: {
            url: string
            altText: string
            longAltText?: string
        }
    }
    willResetMetrics: 'Yes' | 'No'
    isMockQuestion: 'Yes' | 'No'
    bloomTaxonomyLevel: Study.Class.BloomTaxonomyLevel
    subtopic: string
}

@Component({
    components: {
        ExamView,
        FormGroup,
        FormSection,
        TextField,
        QuestionTemplatesList,
        List,
        SelectField,
        Loading,
        ProgressModal,
        FormValidation,
        TextareaField,
        ButtonFooter,
        TitleText,
        PocketButton: UIKit.Button,
    },
})
export default class ExamDraftExport extends Vue {
    s3BucketName = ''
    versionInfo: IVersionInfo = {
        type: 'Patch',
        description: '',
    }
    examDraft: CMS.Class.ExamDraftJSON | null = null
    activeQuestionDrafts: CMS.Class.QuestionDraftJSON[] = []
    updatedQuestionDrafts: CMS.Class.QuestionDraftJSON[] = []
    resetQuestions: TEnhancedQuestion[] = []
    newQuestionDrafts: CMS.Class.QuestionDraftJSON[] = []
    notUpdatedLiveQuestionsDrafts: CMS.Class.QuestionDraftPayload[] = []
    liveQuestionsDrafts: CMS.Class.QuestionDraftPayload[] = []
    knowledgeAreaDrafts: CMS.Class.KnowledgeAreaDraftJSON[] = []
    questionScenarioDrafts: CMS.Class.QuestionScenarioDraftJSON[] = []
    questionScenarios: Study.Class.QuestionScenarioJSON[] = []
    validationMessages: string[] = []
    isLoading = true
    manifestETag: string | null = null
    examETag: string | null = null
    fatalError = false // disables export if there's an error that would break our apps

    exportProgressMessage = 'Starting export'
    exportProgressPercent = 0
    exporting = false

    get numActiveJobs () {
        return this.activeQuestionDrafts
            .filter((q): q is CMS.Class.QuestionDraftJSON => !!(q.job))
            .map(q => q.job && q.job.objectId)
            .filter((current, index, self) => self.indexOf(current) === index).length
    }

    // Increment the version based on the type (Major, Minor, Patch)
    get newVersion (): string {
        if (this.examDraft) {
            const type = this.versionInfo.type
            const originalVersion = this.examDraft.compositeKey.split('/')[1]

            // If this is the first version of the exam, don't increment any version part
            if (!this.examDraft.examMetadataId) {
                return originalVersion
            }

            const versionParts = originalVersion.split('.')
            if (type === 'Major') {
                versionParts[0] = String(Number(versionParts[0]) + 1)
                versionParts[1] = '0'
                versionParts[2] = '0'
            } else if (type === 'Minor') {
                versionParts[1] = String(Number(versionParts[1]) + 1)
                versionParts[2] = '0'
            } else if (type === 'Patch') {
                versionParts[2] = String(Number(versionParts[2]) + 1)
            } else {
                throw new Error(`Unknown Upgrade Type: ${type}`)
            }

            return versionParts.join('.')
        }

        return ''
    }

    get notUpdatedLiveQuestionsDraftRows (): IQuestionDraftRow[] {
        return this.notUpdatedLiveQuestionsDrafts.map(this.mapQuestionToRow)
    }

    get newQuestionDraftRows (): IQuestionDraftRow[] {
        return this.newQuestionDrafts
            .map<IQuestionDraftRow>(q => ({ ...this.mapQuestionToRow(q), draftType: 'New' }))
    }

    get updatedQuestionDraftRows (): IQuestionDraftRow[] {
        return this.updatedQuestionDrafts.map(q => ({ ...this.mapQuestionToRow(q), draftType: 'Updated' }))
    }

    get newAndUpdatedQuestionDrafts () {
        return [
            ...this.newQuestionDrafts,
            ...this.updatedQuestionDrafts,
        ]
    }

    get allQuestionDrafts () {
        return [
            ...this.newQuestionDrafts,
            ...this.updatedQuestionDrafts,
            ...this.notUpdatedLiveQuestionsDrafts,
        ]
    }

    get allQuestionDraftRows () {
        return [
            ...this.newQuestionDraftRows,
            ...this.updatedQuestionDraftRows,
            ...this.notUpdatedLiveQuestionsDraftRows,
        ]
    }

    get notArchivedQuestionDraftRows () {
        return this.allQuestionDraftRows.filter(q => q.isArchived === 'No' && q.isMockQuestion === 'No')
    }

    get notArchivedMockQuestionDrafts () {
        return this.allQuestionDrafts.filter(q => !q.isArchived && q.isMockQuestion)
    }

    get archivedQuestionDrafts () {
        return this.allQuestionDrafts.filter(q => q.isArchived)
    }

    get freeCount () {
        return this.allQuestionDrafts.filter(q => q.isSpecial && !q.isArchived).length
    }

    get exportableImageUrls (): string[] {
        return this.allQuestionDrafts.reduce((acc, qDraft) => {
            const updatedAcc = acc

            if (qDraft.images) {
                if (qDraft.images.explanation) {
                    updatedAcc.push(qDraft.images.explanation.url)
                }
                if (qDraft.images.passage) {
                    updatedAcc.push(qDraft.images.passage.url)
                }
            }

            return updatedAcc
        }, [] as string[])
    }

    get mockExamDraftsWithExportableQuestionsAndIsNewMockExam () {
        const newActiveQuestionSerialsSet = new Set(
            this.newQuestionDrafts.filter(q => q.draftStatus === 'active').map(q => q.objectId)
        )
        return ((
            this.examDraft
            && mockExamDraftsModule.getters.getMockExamDraftsByExamDraftId(this.examDraft.objectId)
        ) || [])
            .map(med => {
                // remove new questions that are active in jobs still from the mockExamDraft question serials
                const inactiveQuestionSerials = med.questionSerials.filter(qs => !newActiveQuestionSerialsSet.has(qs))
                return {
                    ...med,
                    isNewMockExam: !med.mockExamId,
                    mockExamId: med.mockExamId || med.objectId,
                    questionSerials: inactiveQuestionSerials,
                }
            })
    }

    get newCompositeKey () {
        return this.examDraft
            ? `${this.examDraft.compositeKey.split('/')[0]}/${this.newVersion}`
            : null
    }

    get fullImagesExportPayload (): Parameters<CMS.Cloud.exportImages>[0] | null {
        if (this.examDraft && this.exportableImageUrls) {
            return {
                compositeKey: `${this.examDraft.compositeKey.split('/')[0]}/${this.newVersion}`,
                oldCompositeKey: this.examDraft.compositeKey,
                imageUrls: this.exportableImageUrls,
            }
        }

        return null
    }

    get questionCountsByKA () {
        return this.notArchivedQuestionDraftRows.reduce((acc, q) => {
            if (!(q.knowledgeAreaDraft in acc)) {
                acc[q.knowledgeAreaDraft] = 0
            }

            if (q.isMockQuestion === 'No') {
                acc[q.knowledgeAreaDraft]++
            }

            return acc
        }, {} as { [key: string]: number })
    }

    get liveQuestionDraftRows (): Partial<IQuestionDraftRow>[] {
        return this.liveQuestionsDrafts.map(question => {
            const kaObj = this.knowledgeAreaDrafts
                .find(ka => question.knowledgeAreaDraft
                    && 'objectId' in question.knowledgeAreaDraft
                    && ka.objectId === question.knowledgeAreaDraft.objectId
                )
            const kaName = kaObj && kaObj.name
            const subtopic = question.subtopicId && kaObj?.subtopics?.find(sub => sub.id === question.subtopicId)?.name

            return {
                objectId: question.objectId,
                knowledgeArea: kaName,
                type: question.type,
                prompt: question.prompt,
                serial: question.serial,
                explanation: question.explanation || '',
                passage: question.passage || '',
                reference: question.references ? question.references.join(' ') : '',
                isFree: question.isSpecial ? 'Yes' : 'No',
                isArchived: question.isArchived ? 'Yes' : 'No',
                percentCorrect: question.percentCorrect,
                answeredCount: (question.answeredCorrectlyCount || 0) + (question.answeredIncorrectlyCount || 0),
                explanationImage: question.images?.explanation ? 'Yes' : 'No',
                passageImage: question.images?.passage ? 'Yes' : 'No',
                isMockQuestion: question.isMockQuestion ? 'Yes' : 'No',
                subtopic: subtopic || '',
                bloomTaxonomyLevel: question.bloomTaxonomyLevel || 'None',
            }
        })
    }

    get allQuestionDraftsWithFixedImageUrlsAndExpandedKAs () {
        return this.allQuestionDrafts.map(q => {
            const ka = this.knowledgeAreaDrafts
                .find(kad => kad.objectId === (q.knowledgeAreaDraft as Parse.Pointer).objectId)
            if (ka) {
                q.knowledgeAreaDraft = {
                    ...ka,
                    examDraft: objPointer(ka.examDraft.objectId)('ExamDraft'),
                }
            }
            if (q.images) {
                if (q.images.explanation) {
                    q.images.explanation.url = this.imageUrlRegex(q.images.explanation.url)
                }
                if (q.images.passage) {
                    q.images.passage.url = this.imageUrlRegex(q.images.passage.url)
                }
            }

            // coercing QuestionDraftPayload to QuestionDraftJSON to make TS happy. This getter is only used
            // when questions are being sent to server and the differences between the two types don't matter
            return q as CMS.Class.QuestionDraftJSON
        })
    }

    get exportQuestionListOptions (): IListOptions<IQuestionDraftRow> {
        return {
            listData: this.allQuestionDraftRows,
            listSchema: [
                {
                    propName: 'knowledgeAreaDraft',
                    label: 'Subject',
                    type: 'text',
                    options: {
                        width: 250,
                        group: 0,
                    },
                    data: this.sortedKnowledgeAreas.map(ka => ka.name),
                },
                {
                    propName: 'subtopic',
                    label: 'Subtopic',
                    type: 'text',
                    data: this.sortedKnowledgeAreas?.flatMap(ka => ka.subtopics?.map(sub => sub.name) || []),
                    options: {
                        isHidden: true,
                        group: 0,
                    },
                },
                {
                    propName: 'bloomTaxonomyLevel',
                    label: 'Bloom\'s Taxonomy Level',
                    type: 'text',
                    data: bloomTaxonomyLevels,
                    options: {
                        isHidden: true,
                        group: 0,
                    },
                },
                {
                    propName: 'type',
                    label: 'Type',
                    type: 'text',
                    options: {
                        width: 150,
                        group: 0,
                    },
                    data: [ 'Multiple Choice', 'True/False' ],
                },
                {
                    propName: 'draftType',
                    label: 'Draft Type',
                    type: 'text',
                    data: [ 'New', 'Updated', 'Existing' ],
                    options: {
                        isHidden: true,
                    },
                },
                {
                    propName: 'isFree',
                    label: 'Free',
                    type: 'text',
                    data: [ 'Yes', 'No' ],
                    options: {
                        isHidden: true,
                    },
                },
                {
                    propName: 'isArchived',
                    label: 'Archived',
                    type: 'text',
                    data: [ 'Yes', 'No' ],
                    options: {
                        isHidden: true,
                        filter: 'No',
                    },
                },
                {
                    propName: 'prompt',
                    label: 'Prompt',
                    type: 'text',
                    options: {
                        style: 'overflow-ellipsis',
                        group: 1,
                        minWidth: 250,
                    },
                },
                {
                    propName: 'willResetMetrics',
                    label: 'Metrics Will Reset',
                    type: 'text',
                    data: [ 'Yes', 'No' ],
                    options: {
                        isHidden: true,
                    },
                },
                {
                    propName: 'isMockQuestion',
                    label: 'Mock Question',
                    type: 'text',
                    data: [ 'Yes', 'No' ],
                    options: {
                        isHidden: true,
                    },
                },
            ],
            defaultSort: {
                propName: 'draftType',
                sortDir: 'DESC',
            },
            listDataModifiers: [
                data => data.isArchived === 'Yes' && { opacity: '0.3' },
                data => data.draftType === 'Updated' && { backgroundColor: 'rgba(255, 150, 0, 0.3)' },
                data => data.draftType === 'New' && { backgroundColor: 'rgba(100, 255, 100, 0.2)' },
            ],
            listDataIcons: [
                data => data.isFree === 'Yes' && {
                    iconName: 'gift',
                    label: 'Special',
                    styles: {
                        color: 'white',
                        backgroundColor: 'darkgreen',
                        fontSize: '15px',
                    },
                },
                data => data.willResetMetrics === 'Yes' && {
                    iconName: 'broom',
                    label: 'Reset Metrics',
                    styles: {
                        color: 'rgb(255, 0, 0)',
                        backgroundColor: '#fff',
                        fontSize: '15px',
                    },
                },
            ],
        }
    }

    get sortedKnowledgeAreas (): CMS.Class.KnowledgeAreaDraftJSON[] {
        // We don't show archived KAs in ExamView, so would be confusing to show them here
        return this.knowledgeAreaDrafts.filter(kaDraft => !kaDraft.isArchived).sort((a, b) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase(), undefined, { numeric: true })
        )
    }

    async mounted () {
        this.isLoading = true

        try {
            this.s3BucketName = await examsModule.actions.fetchS3BucketName()

            const examDraftId = typeof this.$route.params.examDraftId === 'string'
                && this.$route.params.examDraftId
                
            this.examDraft = examDraftId && (await this.fetchOrGetExamDraft(examDraftId)) || null

            if (!examDraftId || !this.examDraft) {
                this.fatalError = true
                this.validationMessages.push('error/Error: Unable to load export data.')
                return
            }

            // Fetch all the question drafts for the current exam
            const examDraftQuestions = (await questionDraftsModule.actions.fetchQuestionDrafts({
                equalTo: {
                    examDraft: { __type: 'Pointer', className: 'ExamDraft', objectId: examDraftId },
                },
            })).results

            this.activeQuestionDrafts = examDraftQuestions.filter(q => q.draftStatus === 'active')
            const inactiveQuestionDrafts = examDraftQuestions
                .filter(q => q.draftStatus !== 'active')
                .map(this.removeCKEditorTags)

            this.newQuestionDrafts = inactiveQuestionDrafts.filter(q => !q.examDataId)
            this.updatedQuestionDrafts = inactiveQuestionDrafts.filter(q => q.examDataId)

            // Fetch the exam's old questions, in IQuestionDraft form
            const liveQuestions = this.examDraft
                && this.examDraft.examMetadataId
                && await questionsModule.actions.fetchQuestionsByExam({ examMetadataId: this.examDraft.examMetadataId })

            // package up any questions that will have their metrics reset on export
            const resetSerials = this.updatedQuestionDrafts.reduce((acc, question) =>
                (question.willResetMetrics && question.serial)
                    ? acc.add(question.serial)
                    : acc
            , new Set<string>())
            if (liveQuestions) {
                this.resetQuestions = liveQuestions
                    .filter(q => resetSerials.has(q.serial))
                    .map(q => ({
                        ...q,
                        subject: objPointer(q.subject.objectId)('Subject'),
                    }))
            }

            // Fetch knowledge areas and simplify examDraft to pointer to reduce export payload size
            this.knowledgeAreaDrafts = (await kaDraftsModule.actions.fetchKADraftsByExamDraftId(examDraftId))
                .map(ka => ({
                    ...ka,
                    examDraft: objPointer(ka.examDraft.objectId)('ExamDraft'),
                }))

            // Fetch question scenario drafts and live question scenarios
            this.questionScenarioDrafts = await questionScenarioDraftsModule.actions
                .fetchQuestionScenarioDraftsByExamDraftId(examDraftId)
            this.questionScenarios = this.examDraft.examMetadataId
                && await questionScenariosModule.actions.fetchQuestionScenarios({
                    examMetadataId: this.examDraft.examMetadataId,
                }) || []

            // check that there are knowledge area drafts for all original questions
            const missingKnowledgeAreas = (liveQuestions || []).reduce<{ [kaName: string]: Study.Class.SubjectJSON }>(
                (acc, q) => {
                    const subject = q.subject as Study.Class.SubjectJSON
                    if ((subject.name in acc)) {
                        return acc
                    }

                    const isMissing = !this.knowledgeAreaDrafts.find(ka => ka.name === subject.name)

                    if (isMissing) {
                        acc[subject.name] = subject
                    }

                    return acc
                }, {})

            // if missing knowledge areas, create them and refetch all
            if (missingKnowledgeAreas && missingKnowledgeAreas.length) {
                const kaPromises = Object.values(missingKnowledgeAreas)
                    .map(ka => kaDraftsModule.actions.createKADraft({
                        name: ka.name,
                        examDraftId: this.examDraft?.objectId || '',
                        isArchived: true,
                        subjectId: ka.objectId,
                    }))
                await Promise.all(kaPromises)
                this.knowledgeAreaDrafts = await kaDraftsModule.actions.fetchKADraftsByExamDraftId(examDraftId)
            }

            this.liveQuestionsDrafts = liveQuestions
                && await Promise.all(liveQuestions.map(async item => {
                    const knowledgeAreaDraft = this.knowledgeAreaDrafts
                        .find(ka => ka.name === (item.subject as Study.Class.SubjectJSON).name)

                    return await questionDraftsModule.actions.convertPQToPQDraft({
                        question: item,
                        examDraft: this.examDraft || undefined,
                        knowledgeAreaDraft,
                    })
                })
                )
                || []

            // live questions (that have been converted to drafts) that have no corresponding inactive question draft
            this.notUpdatedLiveQuestionsDrafts = 
                this.liveQuestionsDrafts.filter(question => !inactiveQuestionDrafts.find(
                    qDraft => !!qDraft.examDataId && qDraft.serial === question.serial
                ))

            // fetch mock exam drafts
            await mockExamDraftsModule.actions.fetchMockExamDrafts({
                examDraftId: this.examDraft.objectId,
            })

            // warn if exam has 0 free questions
            if (this.freeCount === 0) {
                this.fatalError = true
                this.validationMessages.push('error/Error: This exam has 0 free questions.')
            }

            // warn if any of the questions are missing serial
            const questionSerialIssues = this.allQuestionDrafts.filter(q => !q.serial)
            if (questionSerialIssues.length) {
                this.fatalError = true
                this.validationMessages.push(`error/Error: The following questions (objectIDs) are missing
                serial number: ${questionSerialIssues.map(q => q.objectId)}`)
            }

            // warn if any of the questions is missing KA
            const questionKaIssues = this.allQuestionDrafts.filter(q => !q.knowledgeAreaDraft)
            if (questionKaIssues.length) {
                this.fatalError = true
                this.validationMessages.push(`error/Error: The following questions (serials) are missing
                knowledge area(subject): ${questionKaIssues.map(q => q.serial)}`)
            }

            // warn if any of the questions is missing explanation
            const questionExplanationIssues = this.allQuestionDrafts.filter(q => !q.explanation)
            if (questionExplanationIssues.length) {
                this.fatalError = true
                this.validationMessages.push(`error/Error: The following questions (serials) are missing 
                explanation: ${questionExplanationIssues.map(q => q.serial)}`)
            }

            // warn if we only have question drafts for part of a scenario
            const partialScenarios = this.checkForPartialScenarios()
            if (partialScenarios.length) {
                partialScenarios.forEach(partialScenario => {
                    this.validationMessages.push(`warning/Warning: Scenario ${partialScenario.key}
                    has ${partialScenario.totalCount} questions, but only ${partialScenario.updatedCount}
                    will be updated in this export.`)
                })
            }

            // warn if we're updating a serial that also exists in other exams
            const sharedSerials = await this.checkForSharedSerials()
            if (sharedSerials.length && sharedSerials.length <= 5) {
                sharedSerials.forEach(sharedSerial => {
                    const numExams = sharedSerial.examNames.length
                    this.validationMessages.push(`warning/Warning: Shared serials - Question serial ${
                        sharedSerial.serial
                    } will be updated in this export. This question serial is also in ${
                        numExams === 1 ? 'another exam' : `${numExams} other exams`
                    }: ${sharedSerial.examNames.join(', ')}.`)
                })
            } else if (sharedSerials.length) {
                const uniqueExamNames = [ ...new Set(sharedSerials.flatMap(sharedSerial => sharedSerial.examNames)) ]
                const numExams = uniqueExamNames.length
                this.validationMessages.push(`warning/Warning: There are ${
                    sharedSerials.length
                } question serials that will be updated in this export that are also in ${
                    numExams === 1 ? 'another exam' : `${numExams} other exams`
                }: ${uniqueExamNames.join(', ')}.`)
            }

            // warn if we have any question drafts with invalid scenario draft pointers
            const qDraftIdsWithInvalidScenario = this.checkForQDraftsWithInvalidScenario()
            if (qDraftIdsWithInvalidScenario.length) {
                this.fatalError = true
                const errorMessage = `The following question drafts have invalid scenario pointers:
                ${qDraftIdsWithInvalidScenario.join(', ')}`
                Sentry.captureException(new Error(errorMessage))
                this.validationMessages.push(`error/Error: ${errorMessage}. Contact dev to repair scenario data`)
            }

            // warn if we have any scenario drafts with invalid question draft serials
            const scenarioIdsWithInvalidSerials = this.checkForScenariosWithInvalidSerials()
            if (scenarioIdsWithInvalidSerials.length) {
                this.fatalError = true
                const errorMessage = `The following scenarios have invalid serial references:
                ${scenarioIdsWithInvalidSerials.join(', ')}`
                Sentry.captureException(new Error(errorMessage))
                this.validationMessages.push(`error/Error: ${errorMessage}. Contact dev to repair scenario data`)
            }

            // Warn if trying to archive questions outside of a major export
            const oldArchivedCount = this.liveQuestionDraftRows.filter(q => q.isArchived === 'Yes').length
            if (this.archivedQuestionDrafts.length > oldArchivedCount) {
                const newlyArchivedCount = this.archivedQuestionDrafts.length - oldArchivedCount
                this.validationMessages.push(
                    `warning/Warning: ${ newlyArchivedCount } newly archived question${
                        newlyArchivedCount === 1 ? '' : 's'
                    } - consider using a Major export`
                )
            }

            // Show a warning if the exam draft is not the most recent version of the exam
            if (this.examDraft && this.examDraft.examMetadataId) {
                const currentExamVersion = this.examDraft.compositeKey.split('/')[1]
                const mostRecentExamVersion = await examsModule.actions.fetchMostRecentExamVersion(
                    this.examDraft.compositeKey
                )
                if (mostRecentExamVersion && currentExamVersion !== mostRecentExamVersion) {
                    this.validationMessages
                        .push(
                            'warning/Warning: ' +
                            `${this.examDraft.nativeAppName} has a newer version (${mostRecentExamVersion})`
                        )
                }
            }

            // warn if any unarchived knowledge areas are empty
            const kaWarnings = this.knowledgeAreaDrafts
                .filter(ka => !this.questionCountsByKA[ka.name] && !ka.isArchived)
                .map(ka => `warning/Warning: Knowledge area, ${ka.name}, has 0 questions.`)
            if (kaWarnings.length) {
                this.validationMessages = [
                    ...this.validationMessages,
                    ...kaWarnings,
                ]
            }

            // warn if any images are missing alt text
            const altTextIssues = this.allQuestionDrafts.filter(q => 
                !q.isArchived && (
                    (q.images?.explanation?.url && !q.images.explanation.altText)
                    || (q.images?.passage?.url && !q.images.passage.altText)
                )
            )
            if (altTextIssues.length) {
                this.validationMessages.push(`warning/Warning: There ${altTextIssues.length > 1 ? 'are' : 'is'}` +
                ` ${altTextIssues.length} question${altTextIssues.length > 1 ? 's' : ''} with missing alt text.` +
                ` Serial(s): ${altTextIssues.map(q => q.serial).join(', ')}`)
            }

            // warn if # of active questions decreases
            const exam = examsModule.state.exams
                .find(e => this.examDraft && e.compositeKey === this.examDraft.compositeKey)
            const questionsFewer = exam 
                ? this.allQuestionDrafts.length - (exam.itemCount - exam.archivedCount) 
                : 0
            if (questionsFewer < 0) {
                this.validationMessages.push('error/Warning: The exam you are about to export has ' +
                    `${Math.abs(questionsFewer)} fewer questions than the previous version of the exam.`)
            }

            // warn if there are duplicate serials
            const duplicateInactiveSerials = inactiveQuestionDrafts.reduce<{ [serial: string]: number }>((acc, q) => {
                if (!q.serial) {
                    return acc
                }

                if (!(q.serial in acc)) {
                    acc[q.serial] = 0
                }

                acc[q.serial]++

                return acc
            }, {})
            const duplicateSerialMessages = 
                Object.entries(duplicateInactiveSerials).reduce<string[]>((acc, [ serial, count ]) => {
                    if (count > 1) {
                        acc.push(`error/Duplicate serial: ${serial}`)
                    }

                    return acc
                }, [])

            if (duplicateSerialMessages.length) {
                this.fatalError = true
                this.validationMessages = [
                    ...this.validationMessages,
                    ...duplicateSerialMessages,
                ]
            }

            // warn if any NEW questions have serials that conflict with live questions
            const liveSerials = liveQuestions ? liveQuestions.map(q => q.serial) : []
            const serialConflicts = this.newQuestionDrafts.reduce((acc, q) => {
                if (!q.examDataId && q.serial && liveSerials.includes(q.serial)) {
                    acc.push(q.serial)
                }
                return acc
            }, [] as string[])
            if (serialConflicts.length) {
                const serialConflictMessages = serialConflicts
                    .map(serial => `error/New question serial conflict: ${serial}`)
                this.fatalError = true
                this.validationMessages = [
                    ...this.validationMessages,
                    ...serialConflictMessages,
                ]
            }
        } catch (err) {
            this.validationMessages.push('error/Unable to load export data.')
        }

        // warn if there are questions still in active jobs
        if (this.activeQuestionDrafts.length > 0) {
            this.validationMessages.push(
                `error/Warning: This exam still has ${this.activeQuestionDrafts.length} ${
                    this.activeQuestionDrafts.length === 1 ? 'question' : 'questions'
                } in ${this.numActiveJobs} active ${this.numActiveJobs === 1 ? 'job' : 'jobs'}!`
            )
        }

        this.isLoading = false
    }

    async submitExport () {
        if (this.exporting) {
            throw new Error('ExamDraftExport: submitExport called while already exporting')
        }

        this.exporting = true

        if (!this.fullImagesExportPayload || !this.examDraft) {
            this.validationMessages.push('error/Unknown error exporting exam.')
            this.exporting = false
            return
        }
            
        const oldCompositeKey = this.examDraft.compositeKey
        const compositeKey = this.newCompositeKey

        if (!compositeKey) {
            this.exporting = false
            this.validationMessages.push('error/New composite key cannot be generated')
            throw new Error('New composite key cannot be generated.')
        }

        // check that new version doesn't already exist
        this.exportProgressMessage = 'Checking for version conflicts'
        this.exportProgressPercent = 5
        const versionExists = await examsModule.actions.checkCompositeKeyExists(compositeKey)
        if (versionExists) {
            this.exporting = false
            this.validationMessages.push('error/Cannot export that version. Version already exists.')
            throw new Error(`Error exporting ${compositeKey}. Version already exists.`)
        }

        // export images to s3 and move previous version's images to new folder in s3
        this.exportProgressMessage = 'Copying images to S3 bucket'
        this.exportProgressPercent = 20
        try {
            await examsModule.actions.exportImages(this.fullImagesExportPayload)
        } catch (e) {
            this.exporting = false
            this.validationMessages.push('error/Unable to copy images to S3 bucket.')
            throw e
        }

        // send examMetadata to Parse
        this.exportProgressMessage = 'Exporting exam metadata'
        this.exportProgressPercent = 40
        let newExamMetadataId = ''
        try {
            const totalCount = this.allQuestionDrafts.filter(qd => !qd.isMockQuestion || qd.isArchived).length
            newExamMetadataId = await examsModule.actions.exportExamMetadata({
                newCompositeKey: compositeKey,
                examDraft: {
                    ...this.examDraft,
                    releaseInfo: {
                        ...this.examDraft.releaseInfo,
                        description: this.versionInfo.description,
                    },
                },
                freeCount: this.freeCount,
                archivedCount: this.archivedQuestionDrafts.length,
                totalCount,
            })
        } catch (e) {
            this.exporting = false
            this.validationMessages.push('error/Unable to send exam metadata to App server.')
            throw e
        }

        // send Questions, Subjects, Scenarios, Mock Exams
        this.exportProgressMessage = 'Exporting question data'
        this.exportProgressPercent = 50
        const activeQuestionDraftSet = new Set(this.activeQuestionDrafts.map(q => q.serial))
        const scenariosWithLiveQuestions = this.questionScenarioDrafts.reduce((acc, scenarioDraft) => {
            // Check if scenario draft has any active question drafts
            const areScenarioQuestionDraftsActive = scenarioDraft.questionDrafts
                .some(qd => activeQuestionDraftSet.has(qd.serial))
            // Check if scenario draft has a corresponding live scenario
            const liveScenarioForScenarioDraft = this.questionScenarios
                .find(scenario => scenario.objectId === scenarioDraft.questionScenarioId)
            // Check if scenario is empty
            const isEmptyScenario = scenarioDraft.questionDrafts.length === 0

            // For a new scenario with NO questions,
            // or for a new scenario that still has active questions…
            // => Don't send the questionScenario
            if (!liveScenarioForScenarioDraft && (isEmptyScenario || areScenarioQuestionDraftsActive)) {
                return acc
            }

            // For an existing scenario that has active questions…
            // => Send the questionScenario with the live scenario’s questions
            // Otherwise
            // => Send the questionScenario with the scenario draft's questions
            if (liveScenarioForScenarioDraft && areScenarioQuestionDraftsActive) {
                acc.push({
                    ...scenarioDraft,
                    questionDrafts: liveScenarioForScenarioDraft.questions,
                })
            } else {
                acc.push({
                    ...scenarioDraft,
                    questionDrafts: scenarioDraft.questionDrafts,
                })
            }
            return acc
        }, [] as CMS.Class.QuestionScenarioDraftJSON[])
        try {
            await examsModule.actions.exportQuestionData({
                examMetadataId: newExamMetadataId,
                questions: this.allQuestionDraftsWithFixedImageUrlsAndExpandedKAs,
                subjects: this.knowledgeAreaDrafts,
                questionScenarios: scenariosWithLiveQuestions,
                mockExams: this.mockExamDraftsWithExportableQuestionsAndIsNewMockExam,
            })
        } catch (e) {
            this.exporting = false
            this.validationMessages.push('error/Unable to send questions data to App servers.')
            throw e
        }

        // update redis questions
        this.exportProgressMessage = 'Updating Redis with new questions'
        this.exportProgressPercent = 70
        try {
            await examsModule.actions.updateRedisQuestions(compositeKey)
        } catch (e) {
            this.exporting = false
            this.validationMessages.push('error/Unable to update questions in Redis.')
            throw e
        }

        // update all bundles if major release
        if (oldCompositeKey.split('/')[1].split('.')[0] !== this.newVersion.split('.')[0]) {
            this.exportProgressMessage = 'Updating bundles'
            this.exportProgressPercent = 75
            try {
                await examsModule.actions.updateBundlesForExamMajor({
                    examGuid: oldCompositeKey.split('/')[0].toUpperCase(),
                    newExamId: newExamMetadataId,
                })
            } catch (e) {
                this.exporting = false
                this.validationMessages.push('error/Unable to update bundles.')
                throw e
            }
        }

        // Export keywords if enabled
        if (isKeywordGenerationEnabled(this.examDraft.examMetadataId)) {
            this.exportProgressMessage = 'Exporting keywords'
            this.exportProgressPercent = 75
            try {
                // Grab serials that have been recently created or updated
                const newAndUpdatedSerials = this.newAndUpdatedQuestionDrafts.reduce<string[]>((acc, q) => {
                    if (q.serial) {
                        acc.push(q.serial)
                    }
                    return acc
                }, [])

                await examsModule.actions.exportKeywordDefinitions({
                    serials: newAndUpdatedSerials,
                    examMetadataId: newExamMetadataId,
                    examDraftId: this.examDraft.objectId,
                })
            } catch (e) {
                this.exporting = false
                this.validationMessages.push('error/Unable to export keywords and their definitions to App server.')
                throw e
            }
        }

        // delete old data in Parse
        this.exportProgressMessage = 'Deleting old data'
        this.exportProgressPercent = 80
        try {
            await examsModule.actions.deleteOldParseDataV2({
                examMetadataId: newExamMetadataId,
            })
        } catch (e) {
            this.exporting = false
            this.validationMessages.push('error/Unable to send data to App server directly.')
            throw e
        }

        // delete data from CMS
        this.exportProgressMessage = 'Cleaning up CMS data'
        this.exportProgressPercent = 90
        try {
            await examsModule.actions.cleanUpExport({
                oldCompositeKey,
                compositeKey,
            })
            // Remove the current exam draft from the store state
            examDraftsModule.state.examDrafts = examDraftsModule.state.examDrafts.filter(
                examDraft => !this.examDraft || examDraft.objectId !== this.examDraft.objectId
            )
        } catch (e) {
            this.exporting = false
            this.validationMessages.push('error/Unable to delete old CMS data.')
            throw e
        }

        // store questions with reset metrics and clear global metrics
        if (this.resetQuestions.length) {
            this.exportProgressMessage = 'Storing Questions w/ Quality Reset'
            this.exportProgressPercent = 95
            try {
                await examsModule.actions.questionQualityResetV2({
                    questions: this.resetQuestions,
                })
            } catch (e) {
                this.validationMessages.push('warning/Unable to store and reset Question Quality metrics.')
            }
        }

        await examsModule.actions.fetchExams()

        // log activity
        this.exportProgressMessage = 'Export complete!'
        this.exportProgressPercent = 100
        const safeKnowledgeAreas = Object.entries(this.questionCountsByKA).reduce((acc, [ ka, count ]) => {
            acc[ka.replace(/\./g, '_').replace(/\$/g, '')] = count
            return acc
        }, {} as { [key: string]: number })
        await activitiesModule.actions.createActivity({
            action: 'export',
            subject: {
                type: 'Directory',
                value: oldCompositeKey.split('/')[0].toUpperCase(),
                name: compositeKey,
            },
            type: 'exam',
            data: {
                questionCount: this.notArchivedQuestionDraftRows.length,
                archivedCount: this.archivedQuestionDrafts.length,
                knowledgeAreas: safeKnowledgeAreas,
                specialCount: this.freeCount,
            },
        })

        // redirect to exam view page
        this.$router.push({
            name: 'exam-view',
            params: {
                examId: newExamMetadataId,
            },
        })

        this.exporting = false
    }

    cancelExamExport () {
        if (this.examDraft && this.examDraft.objectId) {
            this.$router.push({
                name: 'exam-draft-edit',
                params: {
                    examDraftId: this.examDraft.objectId,
                },
            })
        }
    }

    mapQuestionToRow (q: CMS.Class.QuestionDraftPayload | CMS.Class.QuestionDraftJSON): IQuestionDraftRow {
        const kaDraft = this.knowledgeAreaDrafts.find(ka => 
            q.knowledgeAreaDraft 
            && (
                ('objectId' in q.knowledgeAreaDraft && ka.objectId === q.knowledgeAreaDraft.objectId)
                || ('id' in q.knowledgeAreaDraft && ka.objectId === q.knowledgeAreaDraft.id)
            )
        )
        const kaName = kaDraft?.name

        const willResetMetrics = ('willResetMetrics' in q && q.willResetMetrics) ? 'Yes' : 'No'
        const subtopic = q.subtopicId && kaDraft?.subtopics?.find(sub => sub.id === q.subtopicId)?.name
        return {
            objectId: q.objectId as string,
            serial: q.serial || '',
            knowledgeAreaDraft: kaName || '',
            prompt: q.prompt || '',
            type: q.type,
            draftType: 'Existing',
            isArchived: q.isArchived ? 'Yes' : 'No',
            isFree: q.isSpecial ? 'Yes' : 'No',
            appName: q.appName || '',
            subCategory: q.subCategory || '',
            answers: q.answers || [],
            explanation: q.explanation || '',
            passage: q.passage || '',
            references: q.references || [],
            distractors: q.distractors || [],
            dateAdded: q.dateAdded,
            images: q.images,
            willResetMetrics,
            isMockQuestion: q.isMockQuestion ? 'Yes' : 'No',
            subtopic: subtopic || '',
            bloomTaxonomyLevel: q.bloomTaxonomyLevel || 'None',
        }
    }

    // remove beginning part of image url
    imageUrlRegex (imageUrl: string) {
        const createRegExp = (str: TemplateStringsArray) => 
            new RegExp(str.raw[0].replace(/\s/gm, ''), '')

        return imageUrl.replace(createRegExp`https:\/\/s3\.amazonaws\.com\/
            (?:[^\/])+\/(?:[^\/])+\/[0-9.]+\/`, '')
    }

    removeCKEditorTags (examDraftQuestion: CMS.Class.QuestionDraftJSON) {
        if (examDraftQuestion.hasComments || examDraftQuestion.hasSuggestions) {
            const mappedExamDraftQuestion = {
                ...examDraftQuestion,
                prompt: stripCKEditorTags(examDraftQuestion.prompt || ''),
                passage: stripCKEditorTags(examDraftQuestion.passage || ''),
                answers: examDraftQuestion.answers && examDraftQuestion.answers.map(stripCKEditorTags),
                distractors: examDraftQuestion.distractors && examDraftQuestion.distractors.map(stripCKEditorTags),
                references: examDraftQuestion.references && examDraftQuestion.references.map(stripCKEditorTags),
                explanation: stripCKEditorTags(examDraftQuestion.explanation || ''),
            }
            return mappedExamDraftQuestion
        }

        return examDraftQuestion
    }

    async fetchOrGetExamDraft (examDraftId: string) {
        return examDraftsModule.getters.getExamDraft(examDraftId)
            || await examDraftsModule.actions.fetchExamDraft(examDraftId)
    }

    checkForPartialScenarios (): { key: string; totalCount: number; updatedCount: number }[] {
        const partialScenarios: {key: string; totalCount: number; updatedCount: number }[] = []
        const exportSerialSet = new Set(this.newAndUpdatedQuestionDrafts.map(q => q.serial as string))
        this.questionScenarioDrafts.forEach(scenarioDraft => {
            const scenarioSerials = scenarioDraft.questionDrafts.map(qd => qd.serial)
            const filteredScenarioSerials = scenarioSerials.filter(serial => exportSerialSet.has(serial))
            // compare the scenario's serials with our new/updated question draft serials
            // we should either be updating 0 or all of a scenario's questions at one time
            if (filteredScenarioSerials.length !== 0 && filteredScenarioSerials.length !== scenarioSerials.length) {
                partialScenarios.push({
                    key: scenarioDraft.key,
                    totalCount: scenarioSerials.length,
                    updatedCount: filteredScenarioSerials.length,
                })
            }
        })
        return partialScenarios
    }

    async checkForSharedSerials (): Promise<{ serial: string; examNames: string[] }[]> {
        const exportSerials = this.newAndUpdatedQuestionDrafts.map(q => q.serial as string)
        const examNamesBySerial = await examsModule.actions.fetchExamNamesByQuestionSerials(exportSerials)

        const liveExam = this.examDraft?.examMetadataId
            && await examsModule.actions.fetchExam(this.examDraft.examMetadataId)
        const currentExamName = (liveExam && liveExam.nativeAppName) || this.examDraft?.nativeAppName
        
        const sharedSerials: { serial: string; examNames: string[] }[] = []

        // For each question draft's serial, check if it belongs to exams other than the current one
        for (const serial in examNamesBySerial) {
            const otherExamNamesWithSerial = examNamesBySerial[serial]?.filter(examName => examName !== currentExamName)
            if (otherExamNamesWithSerial?.length) {
                sharedSerials.push({
                    serial,
                    examNames: otherExamNamesWithSerial,
                })
            }
        }

        return sharedSerials
    }

    checkForQDraftsWithInvalidScenario (): string[] {
        const qDraftIdsWithInvalidScenario: string[] = []
        
        const fetchedScenarioDraftSet = new Set(this.questionScenarioDrafts.map(scenario => scenario.objectId))
        // For each question draft with a scenario pointer
        this.newAndUpdatedQuestionDrafts.forEach(q => {
            // check that the pointer matches a real scenario draft
            if (q.questionScenarioDraft && !fetchedScenarioDraftSet.has(q.questionScenarioDraft.objectId)) {
                qDraftIdsWithInvalidScenario.push(q.serial || q.objectId)
            }
        })

        return qDraftIdsWithInvalidScenario
    }

    checkForScenariosWithInvalidSerials (): string[] {
        const scenarioIdsWithInvalidSerials: string[] = []
        const exportSerialSet = new Set(this.allQuestionDrafts.map(q => q.serial))
        const activeSerialSet = new Set(this.activeQuestionDrafts.map(q => q.serial as string))

        // For each scenario draft
        this.questionScenarioDrafts.forEach(scenarioDraft => {
            // check that all of the scenario's serials are going to be exported
            const missingQuestionDraft = scenarioDraft.questionDrafts.find(qd =>
                !exportSerialSet.has(qd.serial) && !activeSerialSet.has(qd.serial)
            )
            if (missingQuestionDraft) {
                scenarioIdsWithInvalidSerials.push(scenarioDraft.objectId)
            }
        })
        return scenarioIdsWithInvalidSerials
    }

    /**
     * Formats a date in YYYY-MM-DD HH:MM:SS format, since that is the existing format on objects in S3
     * @param {string | Date} originalDate - The date to be formatted
     */
    formatDate (originalDate: string | Date) {
        let dateObj: Date
        // If the date is a string in YYYY-MM-DD HH:MM:SS format, we want to treat it as a UTC time
        if (typeof originalDate === 'string' && originalDate.match(/\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}/)) {
            const dateComponents = originalDate.split(' ')[0].split('-')
            const timeComponents = originalDate.split(' ')[1].split(':')
            const [ year, month, date ] = dateComponents
            const [ hours, minutes, seconds ] = timeComponents
            dateObj = new Date(Date.UTC(
                Number(year), Number(month) - 1, Number(date), Number(hours), Number(minutes), Number(seconds)
            ))
        } else {
            dateObj = new Date(originalDate)
            if (isNaN(Number(dateObj))) {
                dateObj = new Date()
            }
        }
        return `${dateObj.toISOString().slice(0, 10)} ${dateObj.toISOString().slice(11, -5)}`
    }
}
