<template>
    <div class="export">
        <ProgressModal
            v-if="exporting"
            label="Exam export progress"
            :message="exportProgressMessage"
            :progress="exportProgressPercent"
        />
        <Loading v-if="isLoading" />
        <FormValidation :messages="validationMessages" />
        <div 
            v-if="examDraft && examDraft.objectId"
            class="export__exam-name"
        >
            {{ examDraft.nativeAppName }}
        </div>
        <div class="export__header">
            <TitleText
                class="export__title-text"
            >
                Prepare Export
            </TitleText>
            <div v-if="s3BucketName" class="export__s3-bucket-name">
                S3 Bucket: {{ s3BucketName }}
            </div>
        </div>
        <FormSection
            v-if="examDraft"
            class="export__settings"
            label="Export Settings"
            column-grid="true"
        >
            <FormGroup label="Type *">
                <SelectField
                    v-if="examDraft.examMetadataId"
                    v-model="versionInfo.type"
                    :data="['Major', 'Minor', 'Patch']"
                />
                <SelectField 
                    v-else
                    value="Initial Export"
                    :data="['Initial Export']"
                    disabled
                />
            </FormGroup>
            <FormGroup
                label="Prep Content Summary *"
                :subtext="
                    versionInfo.type === 'Major'
                        ? 'X questions, explanations, and references, proprietary to Pocket Prep, are created based on '
                            + 'CompTIA\'s exam blueprint for CAS-004. Our prep matches each subject\'s percentages to '
                            + 'help you master the exam.'
                        : 'Month Year:<br>- Update listed here<br>- Update listed here<br><br>All questions, '
                            + 'explanations, and references, proprietary to Pocket Prep, are created based on '
                            + 'CompTIA\'s exam blueprint for CAS-004. Our prep matches each subject\'s percentages '
                            + 'to help you master the exam.'
                "
            >
                <TextareaField v-model="versionInfo.description" height="85" />
            </FormGroup>
        </FormSection>
        <div v-if="!isLoading && examDraft" class="export__diff">
            <div v-if="examDraft.examMetadataId" class="export__current">
                <h4 class="export__current-header">
                    {{ examDraft.nativeAppName }} - {{ examDraft.compositeKey.split('/')[1] }} (Current)
                </h4>
                <ExamView
                    class="export__current-exam"
                    :exam-id="examDraft.examMetadataId"
                    :show-options="false"
                    :exam-questions="liveQuestionDraftRows"
                />
            </div>
            <div v-if="newVersion" class="export__new-version">
                <h4 class="export__new-version-header">
                    {{ examDraft.nativeAppName }} - {{ newVersion }} (New)
                </h4>
                <div class="export__new-version-details">
                    <div class="export__exam-draft-info">
                        <div class="export__exam-draft-info-item">
                            <label>Native App Name</label>
                            <span>{{ examDraft.nativeAppName }}</span>
                        </div>
                        <div class="export__exam-draft-info-item">
                            <label>Composite Key</label>
                            <span>{{ `${examDraft.compositeKey.split('/')[0]}/${newVersion}` }}</span>
                        </div>
                        <div class="export__exam-draft-info-item">
                            <label>Live Questions</label>
                            <span>{{ notArchivedQuestionDraftRows.length }}</span>
                        </div>
                        <div class="export__exam-draft-info-item">
                            <label>Mock Questions</label>
                            <span>{{ notArchivedMockQuestionDrafts.length }}</span>
                        </div>
                        <div class="export__exam-draft-info-item">
                            <label>Archived Questions</label>
                            <span>{{ archivedQuestionDrafts.length }}</span>
                        </div>
                        <div class="export__exam-draft-info-item">
                            <label># Free Questions</label>
                            <span>{{ freeCount }}</span>
                        </div>
                        <div class="export__exam-draft-info-item">
                            <label># Mock Exams</label>
                            <span>{{ mockExamDraftsWithExportableQuestionsAndIsNewMockExam.length }}</span>
                        </div>
                        <div class="export__exam-draft-info-item">
                            <label>Hide References</label>
                            <span>{{ examDraft.hideReferences ? 'Yes' : 'No' }}</span>
                        </div>
                        <div class="export__exam-draft-info-item">
                            <label>Free</label>
                            <span>{{ examDraft.isFree ? 'Yes' : 'No' }}</span>
                        </div>
                        <div class="export__exam-draft-info-item">
                            <label>Descriptive Name</label>
                            <span>{{ examDraft.descriptiveName }}</span>
                        </div>
                    </div>
                    <FormSection
                        label="New Version"
                        class="export__manifest"
                        :toggleable="true"
                    >
                        <div class="export__manifest-version">
                            <div class="export__manifest-version-item">
                                <label>Name</label>
                                <span>{{ examDraft.releaseInfo.name || 'None' }}</span>
                            </div>
                            <div class="export__manifest-version-item">
                                <label>Version</label>
                                <span>{{ newVersion || 'None' }}</span>
                            </div>
                            <div class="export__manifest-version-item">
                                <label>Description</label>
                                <span>{{ versionInfo.description || 'None' }}</span>
                            </div>
                            <div class="export__manifest-version-item">
                                <label>Message</label>
                                <span>{{ examDraft.releaseInfo.message || 'None' }}</span>
                            </div>
                        </div>
                    </FormSection>
                    <FormSection
                        v-if="sortedKnowledgeAreas"
                        class="export__exam-draft-knowledge-areas"
                        :label="`Subjects (${sortedKnowledgeAreas.length})`"
                        :toggleable="true"
                    >
                        <ul class="export__exam-draft-knowledge-areas-list">
                            <li
                                v-for="ka in sortedKnowledgeAreas"
                                :key="ka.objectId"
                                class="export__exam-draft-knowledge-area"
                                :class="{ 
                                    'export__exam-draft-knowledge-area--archived': ka.isArchived,
                                    'export__exam-draft-knowledge-area--empty': 
                                        !questionCountsByKA[ka.name] && !ka.isArchived
                                }"
                            >
                                {{ ka.name }} ({{ questionCountsByKA[ka.name] || 0 }})
                            </li>
                        </ul>
                    </FormSection>
                    <FormSection
                        :label="`Questions to Export (${allQuestionDrafts.length})`"
                        :toggleable="true"
                    >
                        <List
                            class="export__question-list"
                            store-name="newQuestionsList"
                            :list-options="exportQuestionListOptions"
                        />
                    </FormSection>
                    <FormSection
                        v-if="mockExamDraftsWithExportableQuestionsAndIsNewMockExam.length"
                        class="export__mock-exams-section"
                        :label="`Mock Exams (${mockExamDraftsWithExportableQuestionsAndIsNewMockExam.length})`"
                        :toggleable="true"
                    >
                        <div
                            v-for="mockExam in mockExamDraftsWithExportableQuestionsAndIsNewMockExam"
                            :key="mockExam.mockExamId"
                            class="export__mock-exam-info"
                        >
                            <div class="export__mock-exam-info-item">
                                <label>Name</label>
                                <span>{{ mockExam.name }}</span>
                            </div>
                            <div class="export__mock-exam-info-item">
                                <label>ID</label>
                                <span>{{ mockExam.mockExamId }}</span>
                            </div>
                            <div class="export__mock-exam-info-item">
                                <label>Enabled</label>
                                <span>{{ mockExam.enabled ? 'Yes' : 'No' }}</span>
                            </div>
                            <div class="export__mock-exam-info-item">
                                <label>Duration Minutes</label>
                                <span>{{ (mockExam.durationSeconds / 60) }}</span>
                            </div>
                            <div class="export__mock-exam-info-item">
                                <label># Questions</label>
                                <span>{{ mockExam.questionSerials.length }}</span>
                            </div>
                            <div class="export__mock-exam-info-item">
                                <label>Description</label>
                                <span v-html="mockExam.description" />
                            </div>
                        </div>
                    </FormSection>
                </div>
            </div>
        </div>
        <ButtonFooter v-if="examDraft && examDraft.objectId">
            <template
                #rightSide 
            >
                <div class="export__right-side-buttons">
                    <PocketButton
                        
                        class="export__cancel-button"
                        type="secondary"
                        @click="cancelExamExport"
                    >
                        Cancel
                    </PocketButton>
                    <PocketButton
                        type="primary"
                        :disabled="!versionInfo.description || fatalError || exporting"
                        @click="submitExport"
                    >
                        Run Export
                    </PocketButton>
                </div>
            </template>
        </ButtonFooter>
    </div>
</template>

<script lang="ts" src="@/components/ExamDrafts/ExamDraftExport.ts"></script>

<style lang="scss" scoped>
.export {
    padding: $base;
    flex: 1;
    margin-top: 52px;

    @include breakpoint(mobile) {
        padding: 5px;
    }

    &__mock-exam-info {
        display: flex;
        flex-wrap: wrap;
        border: 1px solid $gray;
        border-radius: 4px;
        padding: 10px;
        margin: 10px 0;
        background-color: white;
    }

    &__mock-exam-info-item {
        display: flex;
        flex-direction: column;
        padding: 10px;
        flex: 1;
        min-width: 200px;
        max-width: 200px;
        margin-right: 10px;

        label {
            color: #666;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 5px;
        }

        span {
            word-wrap: break-word;
        }

        :deep(p) {
            margin: 0;
        }

        &:last-child {
            max-width: 100%;
        }
    }

    &__exam-name {
        margin-top: 22px;
        font-size: 16px;
        color: $slate;
        font-weight: 500;
    }

    &__progress {
        display: flex;
        justify-content: space-between;
    }

    &__title-text {
        margin-top: 16px;
        margin-bottom: 34px;
        font-size: 40px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include breakpoint(mobile) {
            flex-direction: column;
        }
    }

    &__s3-bucket-name {
        font-size: 16px;
        margin: 10px;
        color: $slate;
    }

    &__diff {
        display: flex;
        flex-wrap: wrap-reverse;
    }

    &__current,
    &__new-version {
        width: 50%;

        @include breakpoint(mobile) {
            width: 100%;
        }
    }

    &__current-header,
    &__new-version-header {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
    }

    &__current-exam {
        margin-top: 0;
    }

    &__new-version-details {
        padding: $base;
    }

    &__exam-draft-info {
        display: flex;
        flex-wrap: wrap;
        border: 1px solid $cloudy;
        border-radius: 4px;
        padding: 10px;
        margin-bottom: $base;
        background-color: white;
    }

    &__exam-draft-info-item {
        display: flex;
        flex-direction: column;
        padding: 10px;
        flex: 1;
        min-width: 200px;
        max-width: 200px;
        margin-right: 10px;

        label {
            color: #666;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 5px;
        }

        span {
            word-wrap: break-word;
        }

        &:last-child {
            max-width: 100%;
        }
    }

    &__manifest-version {
        margin: 10px;
        padding: 10px;
        background-color: white;
        border: 1px solid $cloudy;
        border-radius: 4px;
    }

    &__manifest-version-item {
        display: flex;
        flex-direction: column;
        padding: 10px;
        flex: 1;
        min-width: 200px;
        margin-right: 10px;

        label {
            color: #666;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 5px;
        }

        span {
            word-wrap: break-word;
        }
    }

    &__exam-draft-knowledge-areas-list {
        padding: $base 0;
    }

    &__exam-draft-knowledge-area {
        border: 1px solid $gray;
        border-left: 5px solid $gray;
        border-radius: 4px;
        padding: 10px;
        margin-bottom: 10px;
        background-color: white;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__exam-draft-knowledge-area--archived {
        opacity: 0.3;
    }

    &__exam-draft-knowledge-area--empty {
        font-weight: 600;
    }

    &__question-list {
        padding-top: 10px;
    }

    &__right-side-buttons {
        position: absolute;
        right: 0;
        margin-right: 18px;
    }

    &__cancel-button {
        margin-right: 8px;
    }
}
</style>