import Modal from '@/components/Modal.vue'
import { Component, Vue } from 'vue-facing-decorator'
import {
    FormGroup,
    TextField,
    TextareaField,
    FormSection,
    SelectField,
    FormValidation,
    NumberField,
} from '@/components/Forms'
import Loading from '@/components/Loading.vue'
import JobList from '@/components/Jobs/JobList.vue'
import { List, type IListOptions } from '@/components/Lists'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import ActivityList from '@/components/ActivityList.vue'
import { objPointer } from '@/store/ParseUtils'
import type { CMS, Study } from '@pocketprep/types'
import MockExamDraftForm from '@/components/ExamDrafts/MockExamDraftForm.vue'
import { activitiesModule } from '@/store/activities/module'
import type { IKnowledgeAreaDraftDiff, IKnowledgeAreaDraftName } from '@/store/knowledgeAreaDrafts/types'
import type { IMappedQuestionDraft, IParseQuestionDraft } from '@/store/questionDrafts/types'
import type { IMappedQuestion, TEnhancedQuestion } from '@/store/questions/types'
import type { IExamDraftQuestionStats } from '@/store/examDrafts/types'
import jobsModule from '@/store/jobs/module'
import mockExamDraftsModule from '@/store/mockExamDrafts/module'
import examDraftsModule from '@/store/examDrafts/module'
import kaDraftsModule from '@/store/knowledgeAreaDrafts/module'
import examsModule from '@/store/exams/module'
import questionDraftsModule from '@/store/questionDrafts/module'
import questionsModule from '@/store/questions/module'
import mockExamsModule from '@/store/mockExams/module'
import TitleText from '@/components/TitleText.vue'
import ButtonFooter from '@/components/ButtonFooter.vue'
import UIKit from '@pocketprep/ui-kit'
import { usersModule } from '@/store/users/module'
import { bloomTaxonomyLevels, generateId } from '@/utils'
import questionScenarioDraftsModule from '@/store/questionScenarioDrafts/module'

type TSavedExamDraft = ExamDraftForm['examDraft'] & {
    objectId: string
    createdAt: string
    updatedAt: string
}

@Component({
    components: {
        FormGroup,
        TextField,
        TextareaField,
        FormSection,
        SelectField,
        NumberField,
        Loading,
        FormValidation,
        Modal,
        JobList,
        List,
        ActivityList,
        VIcon: FontAwesomeIcon,
        MockExamDraftForm,
        ButtonFooter,
        TitleText,
        PocketButton: UIKit.Button,
        Icon: UIKit.Icon,
        Tooltip: UIKit.Tooltip,
    },
})
export default class ExamDraftForm extends Vue {
    isLoading = false
    error: string | null = null
    examDraft: CMS.Class.ExamDraftJSON | CMS.Class.ExamDraftPayload | null = null
    examDraftKADrafts: IKnowledgeAreaDraftDiff[] = []
    questionDrafts: IParseQuestionDraft[] = []
    mappedQuestionDrafts: IMappedQuestion[] = []
    exam: Study.Class.ExamMetadataJSON | null = null
    validationMessages: string[] = []
    examDraftQuestionStats: IExamDraftQuestionStats | null = null
    examDraftSettingsChanged = false
    icons = {
        arrowRight: faLongArrowAltRight,
    }
    selectedQuestionDrafts: IMappedQuestionDraft[] = []
    bulkQuestionAction = ''
    showNewMockExamDraftForm = false
    originalExamDraftAppId: string | undefined = ''
    originalExamDraftNativeAppName: string | undefined = ''
    originalExamDraftDescriptiveName: string | undefined = ''
    originalExamDraftHideReferences: boolean | undefined = false
    originalExamDraftIsFree: boolean | undefined = false
    originalExamVersionName = ''
    originalExamVersionInfo = ''
    originalExamVersionAlertMessage = ''
    examVersionName = ''
    examVersionInfo = ''
    examVersionAlertMessage = ''
    pageTitle = ''
    showRemoveSubtopicTooltip: string | null = null
    showAddSubtopicTooltip: string | null = null

    get isAdmin () {
        return usersModule.getters.getIsAdmin()
    }

    get bulkQuestionOptions () {
        return [
            {
                group: true,
                label: 'Archiving',
                items: [
                    {
                        label: 'Archive',
                        value: 'archive',
                    },
                    {
                        label: 'Unarchive',
                        value: 'unarchive',
                    },
                ],
            },
            {
                group: true,
                label: 'Change Subject',
                items: (this.examDraftKADrafts
                    .map(ka => {
                        const kaDraft = ka.originalKADraft
                        const kaVal = 'objectId' in kaDraft && `knowledgearea_${kaDraft.objectId}`
                        return kaVal && {
                            label: `${kaDraft.name}${kaDraft.isArchived ? ' (archived)' : ''}`,
                            value: kaVal,
                        }
                    })
                    .filter((ka) => ka) as { label: string; value: string }[])
                    .sort((a, b) =>
                        a.label.toLowerCase() < b.label.toLowerCase()
                            ? -1
                            : a.label.toLowerCase() > b.label.toLowerCase()
                                ? 1
                                : 0
                    ),
            },
        ]
    }

    get newQuestionDrafts () {
        return this.mappedQuestionDrafts.filter(q => q.draftType === 'New')
    }

    get examJobs () {
        return jobsModule.state.jobs.filter(
            job => this.examDraft && 'objectId' in this.examDraft && job.examDraftId === this.examDraft.objectId
        )
    }

    get mockExamDrafts () {
        return this.examDraft?.objectId
            && mockExamDraftsModule.getters.getMockExamDraftsByExamDraftId(this.examDraft.objectId)
            || undefined
    }

    async mounted () {
        this.isLoading = true
        try {
            const examDraftId = typeof this.$route.params.examDraftId === 'string'
                ? this.$route.params.examDraftId
                : this.$route.params.examDraftId?.[0]
            const examId = this.$route.query.examId
            const existingExamDraft = typeof examId === 'string'
                && (await this.fetchOrGetExamDraftByMetadataId(examId))

            // If we have an examDraftId, then get the examDraft and its exam
            if (examDraftId) {
                this.examDraft = await this.fetchOrGetExamDraft(examDraftId) || null
                if (this.examDraft && this.examDraft.examMetadataId) {
                    this.exam = await this.fetchOrGetExam(this.examDraft.examMetadataId) || null
                }

                // fetch its knowledge area drafts
                if (this.examDraft && this.examDraft.objectId) {
                    this.examDraftKADrafts = this.sortKnowledgeAreas(
                        (await examDraftsModule.actions.fetchExamDraftKADrafts(this.examDraft.objectId)).map(item => ({
                            knowledgeAreaDraft: item,
                            originalKADraft: JSON.parse(JSON.stringify(item)),
                        }))
                    )
                }

                // fetch mock exam drafts
                if (this.examDraft && this.examDraft.objectId) {
                    await mockExamDraftsModule.actions.fetchMockExamDrafts({ examDraftId: this.examDraft.objectId })
                }
            } else if (existingExamDraft) {
                // If we have an examId with an existing examDraft, then redirect
                this.$router.replace({
                    name: 'exam-draft-edit',
                    params: {
                        examDraftId: existingExamDraft.objectId,
                    },
                })
            } else if (typeof examId === 'string') {
                // If we have an examId without an examDraft, load that exam's info to populate the form
                this.exam = await this.fetchOrGetExam(examId) || null
                this.examDraft = this.exam && {
                    examMetadataId: this.exam.objectId,
                    nativeAppName: this.exam.nativeAppName,
                    releaseInfo: this.exam.releaseInfo || {
                        name: '',
                        description: '',
                        message: '',
                    },
                    descriptiveName: this.exam.descriptiveName || '',
                    hideReferences: !!this.exam.hideReferences,
                    isFree: !!this.exam.isFree,
                    compositeKey: this.exam.compositeKey,
                    appName: this.exam.nativeAppName,
                    appId: this.exam.appId,
                    description: this.exam.description,
                }

                // fetch the knowledge areas without saving
                this.examDraftKADrafts = this.sortKnowledgeAreas(
                    (await examsModule.actions.fetchSubjects({ examMetadataId: examId })).map(item => ({
                        knowledgeAreaDraft: {
                            name: item.name,
                            isArchived: item.isArchived,
                            subjectId: item.objectId,
                            subtopics: item.subtopics,
                        },
                        originalKADraft: {
                            name: item.name,
                            isArchived: item.isArchived,
                            subjectId: item.objectId,
                            subtopics: item.subtopics,
                        },
                    }))
                )
            } else {
                // If we are creating a brand new examDraft, set all initial fields to defaults
                this.examDraft = {
                    examMetadataId: undefined,
                    nativeAppName: '',
                    releaseInfo: {
                        name: '',
                        description: '',
                        message: '',
                    },
                    descriptiveName: '',
                    hideReferences: false,
                    isFree: false,
                    compositeKey: '',
                    appName: '',
                    appId: '',
                    description: '',
                }
            }

            // Fetch the examDraft's questionDrafts
            await this.fetchAndSetQuestionDrafts()

            // If we have an exam and an examDraft with an objectId, load its existing stats
            await this.updateStats()
        } catch (err) {
            this.validationMessages.push('error/Unable to load some Exam info.')
        }

        this.examVersionName = this.examDraft?.releaseInfo?.name || this.exam?.releaseInfo?.name || ''
        this.examVersionInfo = this.examDraft?.description || this.exam?.description || ''
        this.examVersionAlertMessage = this.examDraft?.releaseInfo?.message || this.exam?.releaseInfo?.message || ''


        this.isLoading = false

        this.examDraft && this.examDraft.objectId ? 
            this.pageTitle = 'Exam Draft' : this.pageTitle = 'Create Exam Draft'

        this.originalExamDraftAppId = this.examDraft?.appId
        this.originalExamDraftNativeAppName = this.examDraft?.nativeAppName
        this.originalExamDraftDescriptiveName = this.examDraft?.descriptiveName
        this.originalExamDraftHideReferences = this.examDraft?.hideReferences
        this.originalExamDraftIsFree = this.examDraft?.isFree
        this.originalExamVersionName = this.examDraft?.releaseInfo?.name || this.exam?.releaseInfo?.name || ''
        this.originalExamVersionInfo = this.examDraft?.description || this.exam?.description || ''
        this.originalExamVersionAlertMessage = 
            this.examDraft?.releaseInfo?.message || this.exam?.releaseInfo?.message || ''
    }

    async bulkEditCheckedQuestions (action: string) {
        this.validationMessages = []

        let questionMap
        if (action === 'archive') {
            questionMap = (q: IMappedQuestionDraft): Partial<IParseQuestionDraft> => ({
                objectId: q.objectId,
                isArchived: true,
            })
        } else if (action === 'unarchive') {
            questionMap = (q: IMappedQuestionDraft): Partial<IParseQuestionDraft> => ({
                objectId: q.objectId,
                isArchived: false,
            })
        } else if (action.includes('knowledgearea_')) {
            const kaId = action.split('_')[1]
            questionMap = (q: IMappedQuestionDraft): Partial<IParseQuestionDraft> => ({
                objectId: q.objectId,
                knowledgeAreaDraft: { objectId: kaId, __type: 'Pointer', className: 'KnowledgeAreaDraft' },
            })
        }

        if (!questionMap) {
            this.validationMessages.push('error/Invalid bulk action')
            return
        }

        this.isLoading = true

        this.selectedQuestionDrafts.length && await questionDraftsModule.actions.upsertQuestionDrafts({
            questionDrafts: this.selectedQuestionDrafts.map(questionMap),
        })

        await this.fetchAndSetQuestionDrafts()
        this.selectedQuestionDrafts = []
        this.validationMessages.push('success/Question(s) successfully updated.')
        this.bulkQuestionAction = ''
        this.isLoading = false
    }

    prepareExport () {
        this.validationMessages = []

        if (this.examDraftSettingsChanged) {
            this.validationMessages.push('error/Save exam draft before preparing export.')
        }
        if (!this.examDraft) {
            throw new Error('Invalid exam draft.')
        }
        // Check for required fields
        if (!this.examDraft.nativeAppName) {
            this.validationMessages.push('error/Missing required field: Exam Name')
        }
        if (!this.examVersionName) {
            this.validationMessages.push('error/Missing required field: Exam Version Name')
        }
        if (!this.examDraft.descriptiveName) {
            this.validationMessages.push('error/Missing required field: Middle Length Descriptive Name')
        }
        if (!this.examVersionInfo) {
            this.validationMessages.push('error/Missing required field: Exam Version Information')
        }
        if (!this.examDraft.appId) {
            this.validationMessages.push('error/Missing required field: App ID')
        }
        if (!this.examVersionAlertMessage) {
            this.validationMessages.push('error/Missing required field: New Exam Version Alert Message')
        }

        if (!this.validationMessages.length) {
            this.$router.push({
                name: 'exam-draft-export',
                params: {
                    examDraftId: 'objectId' in this.examDraft && this.examDraft.objectId || '',
                },
            })
        }
    }

    addKnowledgeArea () {
        this.examDraftKADrafts = [
            ...(this.examDraftKADrafts || []),
            {
                knowledgeAreaDraft: {
                    name: '',
                    isArchived: false,
                },
                originalKADraft: {
                    name: '',
                    isArchived: false,
                },
            },
        ]
    }

    addKnowledgeAreaSubtopic ({ kaIndex, subtopicIndex }: { kaIndex: number; subtopicIndex?: number }) {
        const kaDraft = this.examDraftKADrafts[kaIndex].knowledgeAreaDraft
        if (!kaDraft.subtopics) {
            kaDraft.subtopics = []
        }

        if (subtopicIndex) {
            kaDraft.subtopics.splice(subtopicIndex, 0, {
                id: generateId(),
                name: '',
            })
        } else {
            kaDraft.subtopics.push({
                id: generateId(),
                name: '',
            })
        }
    }

    removeSubtopic ({ kaIndex, subtopicIndex }: { kaIndex: number; subtopicIndex: number }) {
        const kaDraft = this.examDraftKADrafts[kaIndex].knowledgeAreaDraft
        kaDraft.subtopics?.splice(subtopicIndex, 1)
    }

    haveKASubtopicsChanged ({ kaIndex }: { kaIndex: number }) {
        const { originalKADraft, knowledgeAreaDraft } = this.examDraftKADrafts[kaIndex]
        const oldSubtopics = 'subtopics' in originalKADraft
            && originalKADraft.subtopics?.filter(origSubtopic => origSubtopic.name) || []
        const newSubtopics = knowledgeAreaDraft.subtopics?.filter(subtopic => subtopic.name) || []

        if (oldSubtopics.length !== newSubtopics.length) {
            return true // If a subtopic was added or removed
        }

        const areSubtopicsIdentical = newSubtopics.every((newSubtopic, index) => {
            const oldSubtopic = oldSubtopics[index]
            return oldSubtopic?.id === newSubtopic.id && oldSubtopic?.name === newSubtopic.name
        })
        if (!areSubtopicsIdentical) {
            return true // If subtopic names have changed or been rearranged
        }

        return false
    }

    originalTextMessage (currentText?: string, oldText?: string) {
        return (currentText || '') !== (oldText || '')
            ? 'Original: <em>' + (oldText || 'none') + '</em>'
            : false
    }

    sortKnowledgeAreas (knowledgeAreaDrafts: IKnowledgeAreaDraftDiff[]) {
        return knowledgeAreaDrafts
            .sort((a, b) =>
                a.knowledgeAreaDraft.isArchived > b.knowledgeAreaDraft.isArchived
                    ? 1
                    : a.knowledgeAreaDraft.isArchived === b.knowledgeAreaDraft.isArchived
                        ? 0
                        : -1
            )
            .sort((a, b) =>
                a.knowledgeAreaDraft.name.toLowerCase().localeCompare(
                    b.knowledgeAreaDraft.name.toLowerCase(),
                    undefined,
                    { numeric: true }
                )
            )
    }

    async prepareQuestionDrafts (
        cmsQuestionDrafts: IParseQuestionDraft[],
        appQuestions: TEnhancedQuestion[],
        knowledgeAreaDraft: CMS.Class.KnowledgeAreaDraftJSON,
        examDraft: CMS.Class.ExamDraftJSON | CMS.Class.ExamDraftPayload
    ) {
        // convert app server questions (IParseQuestion) to Parse Question Drafts (IParseQuestionDraft)
        const parseQuestions = await Promise.all(appQuestions
            .map(async question => await questionDraftsModule.actions.convertPQToPQDraft({
                question,
                examDraft,
                knowledgeAreaDraft,
            })))

        const mappedQuestions = [ ...parseQuestions, ...cmsQuestionDrafts ]
            .map(
                question => ({
                    ...question,
                    isArchived: knowledgeAreaDraft.isArchived ? true : question.isArchived,
                    draftStatus: 'locked' as const,
                })
            )

        return mappedQuestions
    }

    cancelExamDraftForm () {
        this.$router.push({
            name: 'exam-draft-list',
        })
    }

    async submitUpdateKnowledgeArea (index: number, action?: string) {
        const oldKADraft = this.examDraftKADrafts[index].originalKADraft
        const updatedKADraft = this.examDraftKADrafts[index].knowledgeAreaDraft

        // remove any non-ascii characters - this prevents invisible U+FEFF character (Byte order mark)
        oldKADraft.name = oldKADraft.name.replace(/[^\x20-\x7E]/g, '')
        updatedKADraft.name = updatedKADraft.name.replace(/[^\x20-\x7E]/g, '')

        if (!updatedKADraft.name) {
            alert('You cannot save a knowledge area without a name.')
            return
        }

        this.isLoading = true

        try {
            // Only count questions if updating name or archiving
            const shouldCheckQuestions = updatedKADraft.name !== oldKADraft.name || action

            const {
                questionCount,
                cmsQuestionDrafts,
                appQuestions,
            } = shouldCheckQuestions
                ? await this.checkKAQuestionsAvailable(oldKADraft)
                : {
                    questionCount: 0,
                    cmsQuestionDrafts: [],
                    appQuestions: [],
                }
            
            const confirmMessage = action === 'archive'
                ? `Are you sure you want to archive this knowledge area?
                    ${questionCount} questions will also be archived.`
                : `Are you sure you want to update this knowledge area?
                    ${questionCount} questions will also be affected.`
            const confirmed = shouldCheckQuestions ? questionCount === 0 || confirm(confirmMessage) : true

            if (confirmed) {
                // if exam draft doesn't exist, create it
                const fullExamDraft = this.examDraft && 'objectId' in this.examDraft && this.examDraft.objectId
                    ? this.examDraft as TSavedExamDraft
                    : this.exam
                        ? await examDraftsModule.actions.cloneExamDraftFromExamId(this.exam.objectId)
                        : undefined

                if (!fullExamDraft) {
                    throw new Error('Unable to find or create exam draft.')
                }

                // if knowledge area already in parse, just update it
                if ('objectId' in updatedKADraft) {
                    await kaDraftsModule.actions.updateKADraft({
                        knowledgeAreaDraftId: updatedKADraft.objectId,
                        params: {
                            isArchived: action === 'archive',
                            examDraft: objPointer(fullExamDraft.objectId)('ExamDraft'),
                            name: action === 'archive'
                                ? oldKADraft.name
                                : updatedKADraft.name,
                            subtopics: updatedKADraft.subtopics,
                        },
                    })
                } else {
                    // otherwise, import all new knowledge areas into parse
                    const knowledgeAreaDraftPayloads = this.examDraftKADrafts
                        .map(item =>
                            (item.knowledgeAreaDraft.name === updatedKADraft.name
                            && item.originalKADraft.name === oldKADraft.name)
                                ? { ...updatedKADraft, isArchived: action === 'archive' }
                                : item.originalKADraft.name === ''
                                    ? item.knowledgeAreaDraft
                                    : item.originalKADraft
                        )
                        .filter(item => item.name !== '' && !('examDraft' in item))
                        .map((item: IKnowledgeAreaDraftName) => ({
                            name: item.name,
                            isArchived: item.isArchived,
                            examDraft: objPointer(fullExamDraft.objectId)('ExamDraft'),
                            subjectId: item.subjectId,
                            subtopics: item.subtopics,
                        }))

                    await kaDraftsModule.actions.upsertKADrafts(knowledgeAreaDraftPayloads)
                }

                // pull down all of the knowledge area drafts and save to component
                this.examDraftKADrafts = this.sortKnowledgeAreas(
                    (await examDraftsModule.actions.fetchExamDraftKADrafts(fullExamDraft.objectId))
                        .map(item => ({
                            knowledgeAreaDraft: item,
                            originalKADraft: item,
                        }))
                )

                // get the new knowledge area draft to store on the question drafts
                const savedKADraft = this.examDraftKADrafts
                    .find(item => item.knowledgeAreaDraft.name === updatedKADraft.name)

                if (
                    !savedKADraft ||
                    !savedKADraft.knowledgeAreaDraft ||
                    !('objectId' in savedKADraft.knowledgeAreaDraft)
                ) {
                    throw 'Unable to update knowledge area'
                }

                // add/update question drafts if any were affected
                if (questionCount > 0) {
                    const mappedQuestions = (await this.prepareQuestionDrafts(
                        cmsQuestionDrafts,
                        appQuestions,
                        savedKADraft.knowledgeAreaDraft,
                        fullExamDraft
                    )).map(question => ({
                        ...question,
                        isArchived: action === 'archive' ? true : question.isArchived,
                    }))

                    await questionDraftsModule.actions.upsertQuestionDrafts({
                        questionDrafts: mappedQuestions,
                    })
                }

                // if new exam draft, redirect to edit exam draft form
                if (!this.$route.params.examDraftId) {
                    this.$router.push({
                        name: 'exam-draft-edit',
                        params: { examDraftId: fullExamDraft.objectId },
                    })
                }

                this.validationMessages.push('success/Knowledge area updated successfully')
            }

            this.examDraftKADrafts = this.sortKnowledgeAreas(
                this.examDraftKADrafts.map(item => ({
                    ...item,
                    originalKADraft: JSON.parse(JSON.stringify(item.knowledgeAreaDraft)),
                }))
            )

            await Promise.all([
                this.fetchAndSetQuestionDrafts(),
                this.updateStats(),
            ])
        } catch (e) {
            alert(e)
        }

        this.isLoading = false
    }

    async checkKAQuestionsAvailable (ka: IKnowledgeAreaDraftDiff['originalKADraft']) {
        // fetch all question drafts
        const examQuestionDrafts = this.examDraft ?
                (await questionDraftsModule.actions.fetchQuestionDrafts({
                    equalTo: {
                        examDraft: objPointer(
                            ('objectId' in this.examDraft && this.examDraft.objectId) || ''
                        )('ExamDraft'),
                    },
                })).results
                : [],
            kaQuestionDrafts = examQuestionDrafts
                .filter(q => 'objectId' in ka
                    && q.knowledgeAreaDraft
                    && q.knowledgeAreaDraft.objectId === ka.objectId
                    && !q.isArchived
                )

        // error if any question drafts are active
        if (kaQuestionDrafts.find((q: CMS.Class.QuestionDraftJSON) => q.draftStatus === 'active')) {
            throw new Error('Some effected question drafts are in active jobs and cannot be changed.')
        }

        // get questions from app server
        const subjectId = ('subjectId' in ka && ka.subjectId) || ('objectId' in ka && ka.objectId)
        const kaQuestions = (this.examDraft && this.examDraft.examMetadataId && subjectId)
            ? (await questionsModule.actions.fetchQuestionsByExamAndKA(
                {
                    examMetadataId: this.examDraft.examMetadataId,
                    subjectId,
                }
            ))
            : []

        // combine app server questions and question drafts
        const examQDLib = examQuestionDrafts.reduce<{ [key: string]: CMS.Class.QuestionDraftJSON }>(
                (accum, q) => {
                    const updatedAccum = accum

                    if (q.serial) {
                        updatedAccum[q.serial] = q
                    }

                    return updatedAccum
                }, {}),
            noDraftQs = kaQuestions.filter(q => q.serial && !examQDLib[q.serial])

        return {
            questionCount: noDraftQs.length + kaQuestionDrafts.length,
            cmsQuestionDrafts: kaQuestionDrafts,
            appQuestions: noDraftQs,
        }
    }

    async submitDeleteExamDraft () {
        this.validationMessages = []

        if (this.examJobs.length) {
            this.validationMessages.push('error/Cannot delete exam draft with jobs.')
        }

        if (this.mappedQuestionDrafts.length) {
            this.validationMessages.push('error/Cannot delete exam draft with question drafts.')
        }

        if (!this.validationMessages.length) {
            const confirmDelete = confirm('Are you sure you want to delete this exam draft?' +
                ' This action cannot be undone.')

            if (confirmDelete && this.examDraft && 'objectId' in this.examDraft && this.examDraft.objectId) {
                this.isLoading = true

                await examDraftsModule.actions.deleteExamDraft(this.examDraft.objectId)

                this.$router.push({
                    name: 'exam-draft-list',
                })
            }
        }

        this.isLoading = false
    }

    async submitExamForm () {
        this.validationMessages = []
        this.isLoading = true

        let savedExamDraft: TSavedExamDraft

        if (!this.examDraft) {
            return new Error('Exam draft object does not exist.')
        }

        // Check for required fields
        if (!this.examDraft.nativeAppName) {
            this.validationMessages.push('error/Missing required field: Exam Name')
        }
        if (!this.examVersionName) {
            this.validationMessages.push('error/Missing required field: Exam Version Name')
        }
        if (!this.examDraft.descriptiveName) {
            this.validationMessages.push('error/Missing required field: Middle Length Descriptive Name')
        }
        if (!this.examVersionInfo) {
            this.validationMessages.push('error/Missing required field: Exam Version Information')
        }
        if (!this.examDraft.appId) {
            this.validationMessages.push('error/Missing required field: App ID')
        }
        if (!this.examVersionAlertMessage) {
            this.validationMessages.push('error/Missing required field: New Exam Version Alert Message')
        }

        const updateExistingExamDraft = async (
            examDraft: TSavedExamDraft
        ): Promise<TSavedExamDraft> => {
            // NOTE: creating a new Mock Exam doesn't update this.examDraft
            // as a result, the information is stale for the first save which results
            // in the new Mock Exam being disconnected if you use this.examDraft.mockExamDrafts.
            //
            // However, the Getter mockExamDrafts is always correct so use that instead
            const examDraftPayload: CMS.Class.ExamDraftPayload = {
                ...examDraft,
                mockExamDrafts: this.mockExamDrafts?.map(
                    me => objPointer(me.objectId)('MockExamDraft')
                ),
                releaseInfo: {
                    name: this.examVersionName,
                    description: examDraft.releaseInfo.description || this.exam?.releaseInfo.description || '',
                    message: this.examVersionAlertMessage,
                },
                description: this.examVersionInfo,
            }
            await examDraftsModule.actions.updateExamDraft({
                examDraftId: examDraft.objectId,
                params: examDraftPayload,
            })

            // add update exam activity
            await activitiesModule.actions.createActivity({
                action: 'update',
                subject: {
                    type: 'Pointer',
                    value: examDraft.objectId,
                    name: examDraft.compositeKey,
                },
                type: 'examDraft',
            })

            return examDraft
        }

        const createNewExamDraft = async (examDraft: CMS.Class.ExamDraftPayload):
        Promise<CMS.Class.ExamDraftJSON> => {
            if (!examDraft.examMetadataId) {
                const directory = [ ...Array(8) ].map(() => Math.random().toString(36)[2]).join('')
                examDraft.compositeKey = `${directory}/1.0.0`
                examDraft.appName = examDraft.nativeAppName
            }

            return examDraftsModule.actions.createExamDraft({
                ...examDraft,
                releaseInfo: {
                    name: this.examVersionName,
                    description: examDraft.releaseInfo.description || this.exam?.releaseInfo.description || '',
                    message: this.examVersionAlertMessage,
                },
                description: this.examVersionInfo,
            })
        }

        // import knowledge areas if necessary
        const importKnowledgeAreas = async (
            examDraftKADrafts: IKnowledgeAreaDraftDiff[],
            examDraft: TSavedExamDraft
        ) => {
            const newKnowledgeAreas = examDraftKADrafts.filter(kaDiff =>
                !('objectId' in kaDiff.knowledgeAreaDraft) && kaDiff.knowledgeAreaDraft.name !== ''
            )
            if (newKnowledgeAreas.length) {
                const newKnowledgeAreaPromises = newKnowledgeAreas
                    .map(item => ({
                        ...item.knowledgeAreaDraft,
                        examDraftId: examDraft.objectId,
                    }))
                    .map(async ka => await kaDraftsModule.actions.createKADraft(ka))
                await Promise.all(newKnowledgeAreaPromises)
            }

            return this.sortKnowledgeAreas(examDraftKADrafts)
        }

        const logActivity = async (examDraft: TSavedExamDraft) => {
            // add update exam activity
            await activitiesModule.actions.createActivity({
                action: examDraft.examMetadataId ? 'update' : 'create',
                subject: {
                    type: 'Pointer',
                    value: examDraft.objectId,
                    name: examDraft.compositeKey,
                },
                type: 'examDraft',
            })
        }

        const createMockExamDrafts = async (examMetadataId: string, examDraftId: string) => {
            // fetch mock exams
            const mockExams = await mockExamsModule.actions.fetchMockExams(examMetadataId)

            // save mock exams
            const mockExamPayloads = mockExams.map(mockExam => ({
                name: mockExam.name,
                description: mockExam.description,
                durationSeconds: mockExam.durationSeconds,
                questionSerials: mockExam.questionSerials,
                enabled: mockExam.enabled,
                mockExamId: mockExam.objectId,
            }))
            return mockExamDraftsModule.actions.createMockExamDrafts({
                examDraftId: examDraftId,
                payload: mockExamPayloads,
            })
        }

        if (!this.validationMessages.length) {
            if ('objectId' in this.examDraft) {
                savedExamDraft = await updateExistingExamDraft(this.examDraft as TSavedExamDraft)
            } else {
                savedExamDraft = await createNewExamDraft(this.examDraft)

                // if this is a live exam, fetch and store mock exam drafts
                if (savedExamDraft.examMetadataId) {
                    savedExamDraft.mockExamDrafts =
                    await createMockExamDrafts(savedExamDraft.examMetadataId, savedExamDraft.objectId)
                }
            }

            const examDraftsKADrafts = this.examDraftKADrafts
            this.examDraftKADrafts = await importKnowledgeAreas(examDraftsKADrafts, savedExamDraft)
            await questionScenarioDraftsModule.actions.cloneQuestionScenariosFromExamDraftId(savedExamDraft.objectId)

            logActivity(savedExamDraft)

            // if new exam draft, redirect to edit exam draft form
            if (!this.$route.params.examDraftId) {
                this.$router.push({
                    name: 'exam-draft-edit',
                    params: { examDraftId: savedExamDraft.objectId },
                })
            }

            // We need to update our component's local state variable or we will lose
            // Mock Exam information that may have changed
            this.examDraft = savedExamDraft
            this.validationMessages.push('success/Exam saved successfully')
        }

        this.examDraftSettingsChanged = false
        this.isLoading = false
    }

    mapQuestionDraft (q: CMS.Class.QuestionDraftJSON): IMappedQuestion {
        const kaObj = this.examDraftKADrafts
            .find(ka => q.knowledgeAreaDraft && 'objectId' in ka.knowledgeAreaDraft
                && ka.knowledgeAreaDraft.objectId === q.knowledgeAreaDraft.objectId)
        const kaName = kaObj && kaObj.knowledgeAreaDraft.name
        const subtopicName = q.subtopicId
            && kaObj?.knowledgeAreaDraft.subtopics?.find(sub => sub.id === q.subtopicId)?.name

        return {
            objectId: q.objectId,
            serial: q.serial || q.objectId || '',
            knowledgeAreaDraft: kaName || '',
            subtopic: subtopicName || '',
            prompt: q.prompt || '',
            type: q.type,
            draftType: q.examDataId ? 'Update' : 'New',
            isArchived: q.isArchived ? 'Yes' : 'No',
            isSpecial: q.isSpecial ? 'Yes' : 'No',
            appName: q.appName || '',
            subCategory: q.subCategory || '',
            answers: q.answers || [],
            explanation: q.explanation || '',
            passage: q.passage || '',
            references: q.references || [],
            distractors: q.distractors || [],
            dateAdded: q.dateAdded,
            jobStatus: q.jobStatus || '',
            isActive: q.draftStatus === 'active' ? 'Yes' : 'No',
            isFlagged: q.isFlagged ? 'Yes' : 'No',
            willResetMetrics: q.willResetMetrics ? 'Yes' : 'No',
            isMockQuestion: q.isMockQuestion ? 'Yes' : 'No',
            bloomTaxonomyLevel: q.bloomTaxonomyLevel || 'None',
        }
    }

    questionDraftClicked (qDraft: IMappedQuestion) {
        if (qDraft.objectId) {
            this.$router.push({
                name: 'question-draft-edit',
                params: {
                    questionDraftId: qDraft.objectId,
                },
            })
        }
    }

    get questionDraftListOptions (): IListOptions<IMappedQuestion> {
        return {
            listData: this.mappedQuestionDrafts,
            listSchema: [
                {
                    propName: 'knowledgeAreaDraft',
                    label: 'Subject',
                    type: 'text',
                    data: this.examDraftKADrafts.map(kaDiff => kaDiff.knowledgeAreaDraft.name),
                    options: {
                        minWidth: 250,
                        maxWidth: 250,
                        group: 0,
                    },
                },
                {
                    propName: 'subtopic',
                    label: 'Subtopic',
                    type: 'text',
                    data: this.examDraftKADrafts
                        .flatMap(kaDiff => kaDiff.knowledgeAreaDraft.subtopics?.map(sub => sub.name) || []),
                    options: {
                        isHidden: true,
                        group: 0,
                    },
                },
                {
                    propName: 'bloomTaxonomyLevel',
                    label: 'Bloom\'s Taxonomy Level',
                    type: 'text',
                    data: bloomTaxonomyLevels,
                    options: {
                        isHidden: true,
                        group: 0,
                    },
                },
                {
                    propName: 'type',
                    label: 'Question Type',
                    type: 'text',
                    options: {
                        minWidth: 150,
                        group: 0,
                    },
                },
                {
                    propName: 'draftType',
                    label: 'Draft Type',
                    type: 'text',
                    data: [ 'New', 'Update' ],
                    options: {
                        isHidden: true,
                        group: 0,
                    },
                },
                {
                    propName: 'isSpecial',
                    label: 'Special',
                    type: 'text',
                    data: [ 'Yes', 'No' ],
                    options: {
                        isHidden: true,
                        group: 0,
                    },
                },
                {
                    propName: 'isActive',
                    label: 'Active',
                    type: 'text',
                    data: [ 'Yes', 'No' ],
                    options: {
                        isHidden: true,
                        group: 0,
                    },
                },
                {
                    propName: 'jobStatus',
                    label: 'Job Status',
                    type: 'text',
                    data: [ 'Writer', 'Editor', 'Completed' ],
                    options: {
                        isHidden: true,
                        group: 0,
                    },
                },
                {
                    propName: 'isFlagged',
                    label: 'Flagged',
                    type: 'text',
                    data: [ 'Yes', 'No' ],
                    options: {
                        isHidden: true,
                        group: 0,
                    },
                },
                {
                    propName: 'prompt',
                    label: 'Question',
                    type: 'text',
                    options: {
                        minWidth: 250,
                        style: 'overflow-ellipsis',
                        group: 1,
                    },
                },
                {
                    propName: 'willResetMetrics',
                    label: 'Metrics Will Reset',
                    type: 'text',
                    data: [ 'Yes', 'No' ],
                    options: {
                        isHidden: true,
                    },
                },
                {
                    propName: 'isMockQuestion',
                    label: 'Mock Question',
                    type: 'text',
                    data: [ 'Yes', 'No' ],
                    options: {
                        isHidden: true,
                    },
                },
            ],
            defaultSort: {
                propName: 'draftType',
                sortDir: 'ASC',
            },
            listDataIcons: [
                data => {
                    if (data.jobStatus === 'Writer') {
                        return { iconName: 'pencil', styles: { color: '#fff', backgroundColor: '#ff731a' } }
                    } else if (data.jobStatus === 'Editor') {
                        return { iconName: 'edit', styles: { color: '#fff', backgroundColor: '#e2e106' } }
                    } else if (data.jobStatus === 'Completed') {
                        return { iconName: 'check', styles: { color: '#fff', backgroundColor: '#92c73d' } }
                    }

                    return false
                },
                data => data.isFlagged === 'Yes'
                    ? { iconName: 'flag', styles: { color: '#fff', backgroundColor: '#e63854' } }
                    : false,
                data => data.isSpecial === 'Yes' && {
                    iconName: 'gift',
                    styles: {
                        color: 'white',
                        backgroundColor: 'darkgreen',
                        fontSize: '15px',
                    },
                },
                data => data.willResetMetrics === 'Yes' && {
                    iconName: 'broom',
                    label: 'Reset Metrics',
                    styles: {
                        color: 'rgb(255, 0, 0)',
                        backgroundColor: '#fff',
                        fontSize: '15px',
                    },
                },
                data =>
                    data.isArchived === 'Yes'
                        ? {
                            iconName: 'archive',
                            label: 'Archived',
                            styles: { color: '#fff', backgroundColor: '#475964' },
                        }
                        : false,
                data => data.isMockQuestion === 'Yes' && {
                    iconName: 'fileAlt',
                    label: 'Mock Question',
                    styles: {
                        color: '#fff',
                        backgroundColor: '#609b03',
                        fontSize: '15px',
                    },
                },
            ],
            listDataModifiers: [
                data => {
                    if (data.jobStatus === 'Writer') {
                        return { backgroundColor: 'rgba(244, 245, 249)' }
                    } else if (data.jobStatus === 'Editor') {
                        return { backgroundColor: 'rgba(255, 246, 229)' }
                    } else if (data.jobStatus === 'Completed') {
                        return { backgroundColor: 'rgba(237, 251, 248)' }
                    } else {
                        return false
                    }
                },
            ],
        }
    }

    async fetchOrGetExamDraft (examDraftId: string) {
        return examDraftsModule.getters.getExamDraft(examDraftId)
            || examDraftsModule.actions.fetchExamDraft(examDraftId)
    }

    async fetchOrGetExam (examMetadataId: string) {
        return examsModule.getters.getExam(examMetadataId)
            || examsModule.actions.fetchExam(examMetadataId)
    }

    async fetchOrGetExamDraftByMetadataId (examMetadataId: string) {
        return examDraftsModule.getters.getExamDraftByMetadataId(examMetadataId)
            || examDraftsModule.actions.fetchExamDraftByMetadataId(examMetadataId)
    }

    async fetchAndSetQuestionDrafts () {
        if (this.examDraft && 'objectId' in this.examDraft && this.examDraft.objectId) {
            this.questionDrafts = (await questionDraftsModule.actions.fetchQuestionDrafts({
                equalTo: {
                    examDraft: {
                        __type: 'Pointer',
                        className: 'ExamDraft',
                        objectId: this.examDraft.objectId,
                    },
                },
            })).results

            this.mappedQuestionDrafts = this.questionDrafts.map(this.mapQuestionDraft)
        }
    }

    async updateStats () {
        if (this.examDraft && 'objectId' in this.examDraft && this.examDraft.objectId) {
            const examDraftStats = await examDraftsModule.actions.fetchExamDraftStats({
                examMetadataId: this.examDraft.examMetadataId,
                examDraftId: this.examDraft.objectId,
            })

            this.examDraftQuestionStats = examDraftStats
        }
    }

    hasUnsavedChanges () {
        this.examDraftSettingsChanged = this.examVersionName !== this.originalExamVersionName
            || this.examVersionInfo !== this.originalExamVersionInfo
            || this.examVersionAlertMessage !== this.originalExamVersionAlertMessage
            || this.examDraft?.appId !== this.originalExamDraftAppId
            || this.examDraft?.nativeAppName !== this.originalExamDraftNativeAppName
            || this.examDraft?.descriptiveName !== this.originalExamDraftDescriptiveName
            || this.examDraft?.hideReferences !== this.originalExamDraftHideReferences
            || this.examDraft?.isFree !== this.originalExamDraftIsFree

        return this.examDraftSettingsChanged
    }
}