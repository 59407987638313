<template>
    <div class="exam">
        <FormValidation :messages="validationMessages" />
        <Loading v-if="isLoading" />
        <template v-else-if="exam">
            <div v-if="isAdmin && showOptions" class="exam__header">
                <div
                    class="exam__bundle"
                >
                    {{ bundleName }}
                </div>
                <TitleText
                    class="exam__title-text"
                    :class="{'exam__title-text--no-bundle': !bundleName}"
                >
                    {{ exam.nativeAppName }}
                </TitleText>
            </div>
            <ul class="exam__info">
                <li class="exam__info-item">
                    <label>Exam Name</label>
                    <span>{{ exam.nativeAppName }}</span>
                </li>
                <li class="exam__info-item">
                    <label>Composite Key</label>
                    <span>{{ exam.compositeKey }}</span>
                </li>
                <li class="exam__info-item">
                    <label>Live Questions</label>
                    <span class="exam__description">{{ questionTypeCounts.liveStandard }}</span>
                </li>
                <li class="exam__info-item">
                    <label>Mock Questions</label>
                    <span class="exam__description">{{ questionTypeCounts.liveMock }}</span>
                </li>
                <li class="exam__info-item">
                    <label>Archived Questions</label>
                    <span class="exam__description">{{ questionTypeCounts.archived }}</span>
                </li>
                <li class="exam__info-item">
                    <label># Free Questions</label>
                    <span class="exam__description">{{ exam.specialQuestions }}</span>
                </li>
                <li class="exam__info-item">
                    <label># Mock Exams</label>
                    <span class="exam__description">{{ exam.mockExams ? exam.mockExams.length : 0 }}</span>
                </li>
                <li v-if="showOptions" class="exam__info-item">
                    <label>GUID</label>
                    <span>{{ exam.compositeKey.split('/')[0].toUpperCase() }}</span>
                </li>
                <li class="exam__info-item">
                    <label>Hide References</label>
                    <span>{{ exam.hideReferences ? 'Yes' : 'No' }}</span>
                </li>
                <li class="exam__info-item">
                    <label>Free</label>
                    <span>{{ exam.isFree ? 'Yes' : 'No' }}</span>
                </li>
                <li class="exam__info-item">
                    <label>App ID</label>
                    <span>{{ exam.appId }}</span>
                </li>
                <li class="exam__info-item">
                    <label>Middle Length Descriptive Name</label>
                    <span>{{ exam.descriptiveName }}</span>
                </li>
                <li v-if="showOptions" class="exam__info-item">
                    <label>Version</label>
                    <span>{{ exam.compositeKey.split('/')[1] }}</span>
                </li>
                <li class="exam__info-item">
                    <label>Exam Version Name</label>
                    <span>{{ exam.releaseInfo.name }}</span>
                </li>
                <li v-if="showOptions" class="exam__info-item">
                    <label>Exam Version Information</label>
                    <span>{{ exam.description }}</span>
                </li>
            </ul>
            <FormSection
                label="Current Version"
                class="exam__current"
                :toggleable="true"
            >
                <ul>
                    <li class="exam__current-version">
                        <div class="exam__current-version-item">
                            <label>Name</label>
                            <span>{{ exam.releaseInfo.name }}</span>
                        </div>
                        <div class="exam__current-version-item">
                            <label>Version</label>
                            <span>{{ exam.version }}</span>
                        </div>
                        <div class="exam__current-version-item">
                            <label>Description</label>
                            <span>{{ exam.releaseInfo.description }}</span>
                        </div>
                        <div class="exam__current-version-item">
                            <label>Message</label>
                            <span>{{ exam.releaseInfo.message || 'None' }}</span>
                        </div>
                    </li>
                </ul>
            </FormSection>
            <FormSection
                class="exam__knowledge-areas"
                :label="`Subjects (${knowledgeAreas ? knowledgeAreas.length : 0})`"
                :toggleable="true"
            >
                <ul class="exam__knowledge-areas-list">
                    <!-- Still reading from legacy exam.knowledgeAreas for now for easy access to question count -->
                    <li
                        v-for="(ka, index) in sortedKnowledgeAreas(exam.knowledgeAreas)"
                        :key="index"
                        class="exam__knowledge-area"
                    >
                        {{ ka.name }} ({{ ka.count }})
                    </li>
                </ul>
            </FormSection>
            <FormSection
                class="exam__question-list-container"
                :label="examQuestionsSectionLabel"
                :toggleable="true"
            >
                <List
                    class="exam__question-list"
                    :store-name="typeof listOptions.listData !== 'function' ? 'examQuestionsList' : '' "
                    :list-options="listOptions"
                    @itemClicked="questionClicked"
                />
            </FormSection>
            <FormSection
                v-if="mockExams.length"
                class="exam__mock-exams-section"
                :label="`Mock Exams (${mockExams.length})`"
                :toggleable="true"
            >
                <div
                    v-for="mockExam in mockExams"
                    :key="mockExam.objectId"
                    class="exam__mock-exam-info"
                >
                    <div class="exam__mock-exam-info-item">
                        <label>Name</label>
                        <span>{{ mockExam.name }}</span>
                    </div>
                    <div class="exam__mock-exam-info-item">
                        <label>ID</label>
                        <span>{{ mockExam.objectId }}</span>
                    </div>
                    <div class="exam__mock-exam-info-item">
                        <label>Enabled</label>
                        <span>{{ mockExam.enabled ? 'Yes': 'No' }}</span>
                    </div>
                    <div class="exam__mock-exam-info-item">
                        <label>Duration Minutes</label>
                        <span>{{ (mockExam.durationSeconds / 60) }}</span>
                    </div>
                    <div class="exam__mock-exam-info-item">
                        <label># Questions</label>
                        <span>{{ mockExam.questionSerials.length }}</span>
                    </div>
                    <div class="exam__mock-exam-info-item">
                        <label>Created</label>
                        <span>{{ new Date(mockExam.createdAt).toLocaleDateString() }}</span>
                    </div>
                    <div class="exam__mock-exam-info-item">
                        <label>Updated</label>
                        <span>{{ new Date(mockExam.updatedAt).toLocaleDateString() }}</span>
                    </div>
                    <div class="exam__mock-exam-info-item">
                        <label>Description</label>
                        <span v-html="mockExam.description" />
                    </div>
                </div>
            </FormSection>
            <Activity
                :activity-query="{
                    subject: {
                        type: 'Directory',
                        value: exam.compositeKey.split('/')[0].toUpperCase()
                    }
                }"
            />
        </template>
        <div v-else-if="!exam">
            Exam {{ $route.params.examId }} not found
        </div>
        <ButtonFooter v-if="exam && showOptions && isAdmin" class="exam__button-footer">
            <template
                #leftSide 
            >
                <div class="exam__left-side-buttons">
                    <PocketButton
                        v-if="examDeleteEnabled && exam.objectId"
                        type="primary-red"
                        @click="submitDeleteExam"
                    >
                        Delete Exam
                    </PocketButton>
                </div>
            </template>
            <template
                #rightSide 
            >
                <div class="exam__right-side-buttons">
                    <PocketButton
                        type="secondary"
                        class="exam__export-button"
                        @click="exportToCSV"
                    >
                        Export to CSV
                    </PocketButton>
                    <PocketButton
                        type="primary"
                        @click="editExamDraftClicked"
                    >
                        {{ hasExistingExamDraft ? `Edit Exam Draft` : `Create Exam Draft` }}
                    </PocketButton>
                </div>
            </template>
        </ButtonFooter>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator'
import { stringify as csvStringify } from 'csv-stringify/browser/esm'
import { List, type IListOptions } from '@/components/Lists'
import { FormSection, FormValidation } from '@/components/Forms'
import Loading from '@/components/Loading.vue'
import Activity from '@/components/ActivityList.vue'
import type { Study } from '@pocketprep/types'
import type { TQuestionType } from '@/store/types'
import type { TEnhancedQuestion } from '@/store/questions/types'
import questionsModule from '@/store/questions/module'
import examsModule from '@/store/exams/module'
import mockExamsModule from '@/store/mockExams/module'
import { usersModule } from '@/store/users/module'
import ButtonFooter from '@/components/ButtonFooter.vue'
import TitleText from '@/components/TitleText.vue'
import UIKit from '@pocketprep/ui-kit'
import examDraftsModule from '@/store/examDrafts/module'
import { bundlesModule } from '@/store/bundles/module'
import { bloomTaxonomyLevels, stripCKEditorTags } from '@/utils'

interface IMappedExamQuestion {
    objectId: string
    type: TQuestionType
    serial: string
    knowledgeArea: string
    prompt: string      // Mapped from "question"
    explanation: string
    passage: string
    reference: string
    isFree: 'Yes' | 'No'
    isArchived: 'Yes' | 'No'
    percentCorrect: number
    answeredCount: number
    explanationImage: 'Yes' | 'No'
    passageImage: 'Yes' | 'No'
    isMockQuestion: 'Yes' | 'No'
    bloomTaxonomyLevel: Study.Class.BloomTaxonomyLevel
    subtopic: string
}

@Component({
    components: {
        List,
        FormSection,
        Loading,
        Activity,
        FormValidation,
        ButtonFooter,
        TitleText,
        PocketButton: UIKit.Button,
    },
})
export default class Exam extends Vue {
    @Prop() readonly examId?: string
    @Prop({ type: Boolean, default: true }) readonly showOptions?: boolean
    @Prop() readonly examQuestions?: IMappedExamQuestion[]

    exam: Study.Class.ExamMetadataJSON | null = null
    knowledgeAreas: Study.Class.SubjectJSON[] | null = null
    isLoading = false
    examQuestionsSectionLabel = 'Exam Questions'
    validationMessages: string[] = []
    examDeleteEnabled = import.meta.env.VUE_APP_ENABLE_EXAM_DELETE || null
    mappedQuestions: IMappedExamQuestion[] = []
    mockExams: Study.Class.MockExamJSON[] = []
    mockExamSerials: Set<string> = new Set()
    bundleName: string | null = null
    hasExistingExamDraft = false

    get isAdmin () {
        return usersModule.getters.getIsAdmin()
    }

    processQuestionsForCSV (examQuestions: TEnhancedQuestion[]) {
        return examQuestions.map(examQuestion => {
            const answers = examQuestion.choices.filter(c => c.isCorrect).map(c => c.text)
            const distractors = examQuestion.choices.filter(c => !c.isCorrect).map(c => c.text)
            const subject = (examQuestion['subject'] as Study.Class.SubjectJSON)
            const subtopic = examQuestion.subtopicId
                && subject.subtopics?.find(sub => sub.id === examQuestion.subtopicId)
            return {
                'serial': examQuestion['serial'],
                'type': examQuestion['type'],
                'archived': examQuestion['isArchived'],
                'isSpecial': examQuestion['isFree'],
                'knowledgeArea': subject.name,
                'question': stripCKEditorTags(examQuestion['prompt'] || ''),
                'answer': stripCKEditorTags(answers[0] || ''),
                'answer2': stripCKEditorTags(answers[1] || ''),
                'answer3': stripCKEditorTags(answers[2] || ''),
                'answer4': stripCKEditorTags(answers[3] || ''),
                'answer5': stripCKEditorTags(answers[4] || ''),
                'answer6': stripCKEditorTags(answers[5] || ''),
                'answer7': stripCKEditorTags(answers[6] || ''),
                'answer8': stripCKEditorTags(answers[7] || ''),
                'answer9': stripCKEditorTags(answers[8] || ''),
                'explanation': stripCKEditorTags(examQuestion['explanation'] || ''),
                'passage': stripCKEditorTags(examQuestion['passage'] || ''),
                'reference': stripCKEditorTags(examQuestion['references'].join(', ') || ''),
                'distractor1': stripCKEditorTags(distractors[0] || ''),
                'distractor2': stripCKEditorTags(distractors[1] || ''),
                'distractor3': stripCKEditorTags(distractors[2] || ''),
                'distractor4': stripCKEditorTags(distractors[3] || ''),
                'distractor5': stripCKEditorTags(distractors[4] || ''),
                'distractor6': stripCKEditorTags(distractors[5] || ''),
                'distractor7': stripCKEditorTags(distractors[6] || ''),
                'distractor8': stripCKEditorTags(distractors[7] || ''),
                'distractor9': stripCKEditorTags(distractors[8] || ''),
                'answeredCount': examQuestion['answeredCorrectlyCount'] + examQuestion['answeredIncorrectlyCount'],
                'percentCorrect': examQuestion['percentCorrect'],
                'dateAdded': examQuestion['addedDate'].toLocaleString(),
                'isMockQuestion': examQuestion['isMockQuestion'] ? 'Yes' : 'No',
                'subtopic': subtopic || '',
                'bloomTaxonomyLevel': examQuestion['bloomTaxonomyLevel'],
            }
        })
    }

    async exportToCSV () {
        this.isLoading = true

        const parseQuestions = this.exam
            && await questionsModule.actions.fetchQuestionsByExam({
                examMetadataId: this.exam.objectId,
                searchConfig: {},
            })
        const processedQuestions = parseQuestions ? this.processQuestionsForCSV(parseQuestions) : []
        const csvRows = processedQuestions.map(q =>
            [ q.serial, q.type, q.archived, q.isSpecial, q.knowledgeArea, q.question, q.answer,
                q.answer2, q.answer3, q.answer4, q.answer5, q.answer6, q.answer7, q.answer8, q.answer9,
                q.explanation, q.passage, q.reference, q.distractor1, q.distractor2, q.distractor3,
                q.distractor4, q.distractor5, q.distractor6, q.distractor7, q.distractor8, q.distractor9,
                q.answeredCount, q.percentCorrect,
                q.dateAdded, q.isMockQuestion, q.subtopic, q.bloomTaxonomyLevel ]
        )
        const generatedCsv = csvRows && (await new Promise((res, rej) =>
            csvStringify(csvRows, { header: true, columns: [
                'Serial',
                'Type',
                'Archived',
                'Free',
                'Subject',
                'Question',
                'Answer',
                'Answer 2',
                'Answer 3',
                'Answer 4',
                'Answer 5',
                'Answer 6',
                'Answer 7',
                'Answer 8',
                'Answer 9',
                'Explanation',
                'Passage',
                'Reference',
                'Distractor 1',
                'Distractor 2',
                'Distractor 3',
                'Distractor 4',
                'Distractor 5',
                'Distractor 6',
                'Distractor 7',
                'Distractor 8',
                'Distractor 9',
                'Answered Count',
                'Percent Correct',
                'Date Added',
                'Is Mock',
                'Subtopic',
                'Bloom\'s Taxonomy Level',
            ] }, (err, output) => err ? rej(err) : res(output))
        ) as string)

        if (!generatedCsv || !this.exam) {
            this.validationMessages.push('error/Unable to generate exam CSV.')
        } else {
            const csvFile = new Blob([ generatedCsv ], { type: 'text/csv' }),
                csvLink = URL.createObjectURL(csvFile)

            const downloadLink = document.createElement('a')
            downloadLink.setAttribute('href', csvLink)
            downloadLink.setAttribute(
                'download',
                `${this.exam.nativeAppName} (${new Date().toLocaleDateString().replace(new RegExp('/', 'g'), '-')})`
            )
            downloadLink.id = 'temporary-csv-link'
            downloadLink.click()
        }

        this.isLoading = false
    }

    async submitDeleteExam () {
        this.validationMessages = []

        const confirmDialog = confirm(
            'Are you sure you want to delete this exam and all related data? ' +
            'Only the selected exam version will be deleted. This action cannot be undone.'
        )

        if (confirmDialog && this.exam) {
            this.isLoading = true

            const deleteSuccessful = await examsModule.actions.deleteExam(this.exam.objectId)

            if (deleteSuccessful) {
                this.$router.push({ name: 'exam-list' })
                return
            }
        }

        this.isLoading = false
        this.validationMessages.push('error/Unable to delete exam.')
    }

    editExamDraftClicked () {
        if (this.exam && this.exam.objectId) {
            this.$router.push({
                name: 'exam-draft-create',
                query: {
                    examId: this.exam.objectId,
                },
            })
        }
    }

    get listOptions (): IListOptions<IMappedExamQuestion> {
        return {
            listData: this.questions,
            listSchema: [
                {
                    propName: 'serial',
                    label: 'Serial',
                    type: 'text',
                    options: {
                        width: 150,
                        group: 0,
                    },
                },
                {
                    propName: 'knowledgeArea',
                    label: 'Subject',
                    type: 'text',
                    options: {
                        width: 200,
                        group: 0,
                    },
                    data: this.knowledgeAreas?.map(ka => ka.name) || undefined,
                },
                {
                    propName: 'subtopic',
                    label: 'Subtopic',
                    type: 'text',
                    data: this.knowledgeAreas?.flatMap(ka => ka.subtopics?.map(sub => sub.name) || []),
                    options: {
                        isHidden: true,
                        group: 0,
                    },
                },
                {
                    propName: 'bloomTaxonomyLevel',
                    label: 'Bloom\'s Taxonomy Level',
                    type: 'text',
                    data: bloomTaxonomyLevels,
                    options: {
                        isHidden: true,
                        group: 0,
                    },
                },
                {
                    propName: 'type',
                    label: 'Type',
                    type: 'text',
                    options: {
                        width: 150,
                        group: 0,
                    },
                    data: [ 'Multiple Choice', 'Multiple Correct Response', 'True/False' ],
                },
                {
                    propName: 'answeredCount',
                    label: '# Answered',
                    type: 'text',
                    options: {
                        width: 100,
                        group: 1,
                        filter: false,
                        sort: true,
                    },
                },
                {
                    propName: 'percentCorrect',
                    label: '% Correct',
                    type: 'percent',
                    options: {
                        width: 80,
                        group: 1,
                        sort: true,
                    },
                },
                {
                    propName: 'prompt',
                    label: 'Question',
                    type: 'text',
                    options: {
                        style: 'overflow-ellipsis',
                        group: 2,
                        width: 420,
                        minWidth: 200,
                    },
                },
                {
                    propName: 'explanation',
                    label: 'Explanation',
                    type: 'text',
                    options: {
                        isHidden: true,
                    },
                },
                {
                    propName: 'passage',
                    label: 'Supplemental Info',
                    type: 'text',
                    options: {
                        isHidden: true,
                    },
                },
                {
                    propName: 'reference',
                    label: 'References',
                    type: 'text',
                    options: {
                        isHidden: true,
                    },
                },
                {
                    propName: 'isArchived',
                    label: 'Archived',
                    type: 'text',
                    options: {
                        isHidden: true,
                        filter: 'No',
                    },
                    data: [ 'Yes', 'No' ],
                },
                {
                    propName: 'isFree',
                    label: 'Free',
                    type: 'text',
                    options: {
                        isHidden: true,
                    },
                    data: [ 'Yes', 'No' ],
                },
                {
                    propName: 'passageImage',
                    label: 'Passage Image',
                    type: 'text',
                    options: {
                        isHidden: true,
                    },
                    data: [ 'Yes', 'No' ],
                },
                {
                    propName: 'explanationImage',
                    label: 'Explanation Image',
                    type: 'text',
                    options: {
                        isHidden: true,
                    },
                    data: [ 'Yes', 'No' ],
                },
                {
                    propName: 'isMockQuestion',
                    label: 'Mock Question',
                    type: 'text',
                    options: {
                        isHidden: true,
                    },
                    data: [ 'Yes', 'No' ],
                },
            ],
            listDataModifiers: [
                data => data.isArchived === 'Yes' && { opacity: '0.5' },
            ],
            listDataIcons: [
                data => data.isFree === 'Yes' && {
                    iconName: 'gift',
                    label: 'Special',
                    styles: {
                        color: 'white',
                        backgroundColor: 'darkgreen',
                        fontSize: '15px',
                    },
                },
                data => data.isMockQuestion === 'Yes' && {
                    iconName: 'fileAlt',
                    label: 'Mock Question',
                    styles: {
                        color: '#fff',
                        backgroundColor: '#609b03',
                        fontSize: '15px',
                    },
                },
            ],
        }
    }

    get questions () {
        return this.examQuestions || this.mappedQuestions
    }

    get questionTypeCounts () {
        const typeCounts = {
            liveStandard: 0,
            liveMock: 0,
            archived: 0,
        }

        this.questions.forEach(q => {
            if (q.isArchived === 'No' && q.isMockQuestion === 'No') {
                typeCounts.liveStandard++
            } else if (q.isArchived === 'No' && q.isMockQuestion === 'Yes') {
                typeCounts.liveMock++
            } else if (q.isArchived === 'Yes') {
                typeCounts.archived++
            }
        })

        return typeCounts
    }

    questionClicked (question: IMappedExamQuestion) {
        this.$router.push({
            name: 'question-view',
            params: {
                questionId: question.objectId,
            },
        })
    }

    sortedKnowledgeAreas (kaObj: Study.Class.ExamMetadataJSON['knowledgeAreas']) {
        const kas = Object.values(kaObj)
        return kas.sort((a, b) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase(), undefined, { numeric: true })
        )
    }

    async fetchOrGetExamDraftByMetadataId (examMetadataId: string) {
        return examDraftsModule.getters.getExamDraftByMetadataId(examMetadataId)
            || examDraftsModule.actions.fetchExamDraftByMetadataId(examMetadataId)
    }

    async mounted () {
        this.isLoading = true
        const [ bundles ] = await Promise.all([
            bundlesModule.actions.fetchBundles(),
        ])

        try {
            const examId = typeof this.$route.params.examId === 'string'
                ? this.$route.params.examId
                : this.examId
            const exam = examId && await examsModule.actions.fetchExam(examId)

            const existingExamDraft = typeof examId === 'string'
                && (await this.fetchOrGetExamDraftByMetadataId(examId))
            this.hasExistingExamDraft = existingExamDraft ? true : false

            if (exam) {
                this.exam = exam
                const examBundle = bundles.find(bundle => bundle.exams.find(e =>{
                    return e.objectId === this.exam?.objectId
                }))
                
                this.bundleName = examBundle ? examBundle.name : null
            }
            if (this.exam) {
                this.knowledgeAreas = (await examsModule.actions.fetchSubjects({
                    examMetadataId: this.exam.objectId,
                })).filter(subject => !subject.isArchived).sort((a, b) =>
                    a.name.toLowerCase().localeCompare(b.name.toLowerCase(), undefined, { numeric: true })
                )
                this.mockExams = await mockExamsModule.actions.fetchMockExams(this.exam.objectId)
                this.mockExamSerials = new Set(this.mockExams.map(me => me.questionSerials).flat())
            }

            if (!this.examQuestions
                && this.exam?.objectId) {
                // Go fetch exam questions as they weren't set by the Component Property
                const questions = await questionsModule.actions.fetchQuestionsByExam({
                    examMetadataId: this.exam?.objectId,
                    searchConfig: {},
                })
                this.mappedQuestions = questions.map(question => ({
                    objectId: question.objectId,
                    knowledgeArea: (question.subject as Study.Class.SubjectJSON).name,
                    subtopic: question.subtopicId
                        && (
                            this.knowledgeAreas?.find(ka => ka.objectId === question.subject.objectId)
                        )?.subtopics?.find(sub => sub.id === question.subtopicId)?.name || '',
                    type: question.type,
                    prompt: question.prompt,
                    serial: question.serial,
                    explanation: question.explanation || '',
                    passage: question.passage || '',
                    reference: question.references.join(', ') || '',
                    isFree: question.isFree ? 'Yes' : 'No',
                    isArchived: question.isArchived ? 'Yes' : 'No',
                    percentCorrect: question.percentCorrect,
                    answeredCount: question.answeredCorrectlyCount + question.answeredIncorrectlyCount,
                    explanationImage: question.explanationImage ? 'Yes' : 'No',
                    passageImage: question.passageImage ? 'Yes' : 'No',
                    isMockQuestion: question.isMockQuestion ? 'Yes' : 'No',
                    bloomTaxonomyLevel: question.bloomTaxonomyLevel || 'None',
                }))
            }
            this.examQuestionsSectionLabel =
            `Exam Questions (${this.questions.length})`
        } catch (err) {
            this.validationMessages.push('error/Unable to load exam.')
        }

        this.isLoading = false
    }
}
</script>

<style lang="scss" scoped>

.exam {
    width: 100%;
    padding: $base;
    margin-top: 52px;

    &__bundle {
        margin-top: 22px;
        font-size: 16px;
        color: $slate;
        font-weight: 500;
    }

    &__title-text {
        margin-top: 16px;
        margin-bottom: 34px;
        font-size: 40px;

        &--no-bundle {
            margin-top: 50px;
        }
    }

    &__options-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: $base;
    }

    &__options {
        button {
            margin-right: 15px;
        }
    }

    &__knowledge-areas {
        width: 100%;
        max-width: inherit;
    }

    &__knowledge-areas-list {
        display: flex;
        flex-direction: column;
        padding: $base 0;
    }

    &__knowledge-area {
        border: 1px solid $cloudy;
        border-left: 5px solid $gray;
        border-radius: 4px;
        padding: 10px;
        margin-bottom: 10px;
        background-color: white;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__mock-exam-info {
        display: flex;
        flex-wrap: wrap;
        border: 1px solid $cloudy;
        border-radius: 4px;
        padding: 10px;
        margin: 10px 0;
        background-color: white;
    }

    &__mock-exam-info-item {
        display: flex;
        flex-direction: column;
        padding: 10px;
        flex: 1;
        min-width: 200px;
        max-width: 200px;
        margin-right: 10px;

        label {
            color: #666;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 5px;
        }

        span {
            word-wrap: break-word;
        }

        :deep(p) {
            margin: 0;
        }

        &:last-child {
            max-width: 100%;
        }
    }

    &__info {
        display: flex;
        flex-wrap: wrap;
        border: 1px solid $cloudy;
        border-radius: 4px;
        padding: 10px;
        margin-bottom: $base;
        background-color: white;
    }

    &__info-item {
        display: flex;
        flex-direction: column;
        padding: 10px;
        flex: 1;
        min-width: 200px;
        max-width: 200px;
        margin-right: 10px;

        label {
            color: #666;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 5px;
        }

        span {
            word-wrap: break-word;
        }

        &:last-child {
            flex: auto;
            max-width: 100%;
        }
    }

    &__current-version {
        margin: 10px;
        padding: 10px;
        background-color: white;
        border: 1px solid $cloudy;
        border-radius: 4px;
    }

    &__current-version-item {
        display: flex;
        flex-direction: column;
        padding: 10px;
        flex: 1;
        min-width: 200px;
        margin-right: 10px;

        label {
            color: #666;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 5px;
        }

        span {
            word-wrap: break-word;
        }
    }

    &__no-current {
        margin: $base;
    }

    &__question-list {
        padding-top: 10px;
    }

    &__left-side-buttons {
        margin-left: 18px;
    }

    &__right-side-buttons {
        position: absolute;
        right: 0;
        margin-right: 18px;
    }

    &__export-button {
        margin-right: 8px;
    }
}
</style>
