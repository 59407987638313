import { Parse, sessionToken, objPointer } from '@/store/ParseUtils'
import type { CMS } from '@pocketprep/types'
import examDraftsModule from '@/store/examDrafts/module'
import questionScenariosModule from '@/store/questionScenarios/module'

/**
 * Clone question scenarios to question scenario drafts by examDraftId
 */
const cloneQuestionScenariosFromExamDraftId = async (
    examDraftId: string
): Promise<CMS.Class.QuestionScenarioDraftJSON[]> => {
    // fetch exam draft
    const examDraft = await examDraftsModule.actions.fetchExamDraft(examDraftId)

    // fail if no exam draft
    if (!examDraft) {
        throw new Error('Unable to find exam draft.')
    }

    // check for existing question scenario drafts and just return them if found
    const questionScenarioDrafts = await fetchQuestionScenarioDraftsByExamDraftId(examDraft.objectId)
    if (questionScenarioDrafts.length) {
        return questionScenarioDrafts
    }

    // If the exam draft is connected to an existing live exam, fetch the lice question scenarios
    if (examDraft.examMetadataId) {
        // otherwise fetch QuestionScenario records and save them as drafts
        const questionScenarios = await questionScenariosModule.actions.fetchQuestionScenarios({
            examMetadataId: examDraft.examMetadataId,
        })
        const newQuestionScenarioDrafts = await Promise.all(questionScenarios.map(async questionScenario => {
            return createQuestionScenarioDraft({
                examDraftId: examDraft.objectId,
                key: questionScenario.key,
                questionDrafts: questionScenario.questions,
                questionScenarioId: questionScenario.objectId,
                sharedPassage: questionScenario.sharedPassage,
                isUnfolding: questionScenario.isUnfolding,
            })
        }))
    
        return newQuestionScenarioDrafts
    }

    return []
}

/**
 *  Fetch all question scenario drafts that are currently in cms server 
 */
const fetchQuestionScenarioDrafts = async (): Promise<CMS.Class.QuestionScenarioDraftJSON[]> => {
    const parseQuestionScenarioDrafts = await new Parse.Query<CMS.Class.QuestionScenarioDraft>('QuestionScenarioDraft')
        .findAll({
            ...sessionToken(),
            batchSize: 2000,
        })

    return parseQuestionScenarioDrafts.map(questionScenarioDraft => questionScenarioDraft.toJSON())
}

/**
 * Fetch questionScenarioDrafts by examDraftId
 */
const fetchQuestionScenarioDraftsByExamDraftId = async (
    examDraftId: string
): Promise<CMS.Class.QuestionScenarioDraftJSON[]> => {
    const questionScenarioDrafts = await new Parse.Query<CMS.Class.QuestionScenarioDraft>('QuestionScenarioDraft')
        .equalTo('examDraft', objPointer(examDraftId)('ExamDraft'))
        .find(sessionToken())

    return questionScenarioDrafts.map(questionScenarioDraft => questionScenarioDraft.toJSON())
}

/**
 * Create new questionScenarioDraft
 */
const createQuestionScenarioDraft = async (
    {
        examDraftId,
        key,
        questionDrafts,
        sharedPassage,
        questionScenarioId,
        isUnfolding,
    }: {
        examDraftId: string
        key: string
        questionDrafts: {
            serial: string
        }[]
        sharedPassage?: string | undefined
        questionScenarioId?: string | undefined
        isUnfolding?: boolean
    }
): Promise<CMS.Class.QuestionScenarioDraftJSON> => {
    const newQuestionScenarioDraft = new Parse.Object<CMS.Class.QuestionScenarioDraftPayload>('QuestionScenarioDraft',
        {
            examDraft: objPointer(examDraftId)('ExamDraft'),
            key,
            questionDrafts,
            sharedPassage,
            questionScenarioId,
            isUnfolding,
        }
    )

    const savedQuestionScenarioDraft = await newQuestionScenarioDraft.save(null, sessionToken())

    return savedQuestionScenarioDraft.toJSON()
}

/**
 * Update questionScenarioDraft
 */
const updateQuestionScenarioDraft = async ({
    objectId,
    questionDrafts,
    sharedPassage,
    isUnfolding,
}: {
    objectId: string
    questionDrafts: {
        serial: string
    }[]
    sharedPassage?: string | undefined
    isUnfolding: boolean
}): Promise<CMS.Class.QuestionScenarioDraftJSON> => {
    // Fetch existing QuestionScenarioDraft
    const questionScenarioDraft = await new Parse.Query<CMS.Class.QuestionScenarioDraft>('QuestionScenarioDraft')
        .get(objectId)

    questionScenarioDraft.set({
        questionDrafts,
        sharedPassage,
        isUnfolding,
    })

    const updatedQuestionScenarioDraft = await questionScenarioDraft.save(null, sessionToken())

    return updatedQuestionScenarioDraft.toJSON()
}

/**
 * Delete questionScenarioDraft
 */
const deleteQuestionScenarioDraft = async (questionScenarioDraftId: string): Promise<boolean> => {
    const questionScenarioDraft = await new Parse.Query<CMS.Class.QuestionScenarioDraft>('QuestionScenarioDraft')
        .get(questionScenarioDraftId)

    try {
        await questionScenarioDraft.destroy(sessionToken())
        return true
    } catch (e) {
        return false
    }
}

const syncScenarioFromQuestion = async ({
    sourceQuestionDraftId,
    questionScenarioDraftId,
    sharedPassage,
    passageLabel,
    isUnfoldingScenario,
}: {
    sourceQuestionDraftId?: string
    questionScenarioDraftId: string
    sharedPassage: string | undefined   // Can pass undefined to clear the passage
    passageLabel: CMS.Class.TPassageLabel | undefined
    isUnfoldingScenario: boolean
}) => {
    const questionScenarioDraft = await new Parse.Query<CMS.Class.QuestionScenarioDraft>('QuestionScenarioDraft')
        .get(questionScenarioDraftId)
    const questionDrafts = await new Parse.Query<CMS.Class.QuestionDraft>('QuestionDraft')
        .equalTo('questionScenarioDraft', objPointer(questionScenarioDraftId)('QuestionScenarioDraft'))
        .find(sessionToken())

    const sourceQuestionDraft = questionDrafts.find(qDraft => {
        return qDraft.id === sourceQuestionDraftId
    })
    const sourceQuestionDraftSerial = sourceQuestionDraft?.get('serial')

    // Check whether source question needs to be added to the scenario
    const isSourceQuestionInScenario = questionScenarioDraft.get('questionDrafts').find(({ serial }) =>
        serial === sourceQuestionDraftSerial
    )
    if (!isSourceQuestionInScenario && sourceQuestionDraftSerial) {
        questionScenarioDraft.addUnique('questionDrafts', { serial: sourceQuestionDraftSerial })
    }

    const scenarioQs = questionScenarioDraft.get('questionDrafts')
    const isFirstScenarioQuestion = scenarioQs.findIndex(qd => qd.serial === sourceQuestionDraftSerial) === 0

    // Set or unset passageLabel on all question drafts
    if (passageLabel) {
        questionDrafts.forEach(qDraft => qDraft.set('passageLabel', passageLabel))
    } else {
        questionDrafts.forEach(qDraft => qDraft.unset('passageLabel'))
    }

    // Set or unset passage on all question drafts, unless scenario is unfolding
    if (!isUnfoldingScenario) {
        if (sharedPassage) {
            questionScenarioDraft.set('sharedPassage', sharedPassage)
            questionDrafts.forEach(qDraft => qDraft.set('passage', sharedPassage))
        } else {
            questionScenarioDraft.unset('sharedPassage')
            questionDrafts.forEach(qDraft => qDraft.unset('passage'))
        }
    } else if (isUnfoldingScenario && isFirstScenarioQuestion) {
        // if scenario is unfolding, only use the first question's passage for scenario's sharedPassage
        if (sharedPassage) {
            questionScenarioDraft.set('sharedPassage', sharedPassage)
        } else {
            questionScenarioDraft.unset('sharedPassage')
        }
    }

    await Promise.all([
        questionScenarioDraft.save(null, sessionToken()),
        Parse.Object.saveAll(questionDrafts, sessionToken()),
    ])
}

export default {
    cloneQuestionScenariosFromExamDraftId,
    fetchQuestionScenarioDrafts,
    fetchQuestionScenarioDraftsByExamDraftId,
    createQuestionScenarioDraft,
    updateQuestionScenarioDraft,
    deleteQuestionScenarioDraft,
    syncScenarioFromQuestion,
}
