<template>
    <div class="exam-draft-list">
        <Loading v-if="isLoading" />
        <div
            class="exam-draft-list__title"
        >
            <TitleText>
                Exam Drafts
            </TitleText>
            <PocketButton
                class="exam-draft-list__create-draft-btn"
                @click="createExamDraftClicked"
            >
                Create Exam Draft
            </PocketButton>
        </div>
        <List
            v-if="!isLoading"
            store-name="examDraftsList"
            list-unit="Exam Draft"
            :list-options="listOptions"
            :force-show-controls="true"
            :hidePagination="true"
            :pageSize="listOptions.listData.length"
            @itemClicked="examDraftClicked"
        >
            <template #filterTypeDropdownListItem="{ item }">
                <template v-if="item.label === 'Bundle'">
                    <div class="exam-draft-list__filter-type-dropdown-item-container">
                        <div class="exam-draft-list__filter-type-dropdown-section-title">
                            TABLE COLUMN
                        </div>
                        <div class="exam-draft-list__filter-type-dropdown-item">
                            {{ item.label }}
                        </div>
                    </div>
                </template>
                <template v-if="item.label === 'Free Exam'">
                    <div class="exam-draft-list__filter-type-dropdown-item-container">
                        <div class="exam-draft-list__filter-type-dropdown-section-title">
                            T/F FILTERS
                        </div>
                        <div class="exam-draft-list__filter-type-dropdown-item">
                            {{ item.label }}
                        </div>
                    </div>
                </template>
            </template>
            <template #tableCellValue="{ row, column }">
                <template v-if="column.propName === 'bundle' && row.bundle === 'No Bundle'">
                    <div class="exam-draft-list__empty-bundle-cell">--</div>
                </template>
                <template v-if="column.propName === 'numOfSubjects'
                    && row.numOfSubjects !== row.numOfDraftSubjects">
                    <span>{{ row.numOfSubjects }}</span>
                    <Icon type="arrow" class="exam-draft-list__changes-arrow"></Icon>
                    <span>{{ row.numOfDraftSubjects }}</span>
                </template>
                <template v-if="column.propName === 'numOfSubtopics'
                    && row.numOfSubtopics !== row.numOfDraftSubtopics">
                    <span>{{ row.numOfSubtopics }}</span>
                    <Icon type="arrow" class="exam-draft-list__changes-arrow"></Icon>
                    <span>{{ row.numOfDraftSubtopics }}</span>
                </template>
                <template v-if="column.propName === 'numOfStandardQuestions'
                    && row.numOfStandardDraftQuestions > 0">
                    <span>{{ row.numOfStandardQuestions }}</span>
                    <Icon type="arrow" class="exam-draft-list__changes-arrow"></Icon>
                    <span>{{ row.numOfStandardQuestions + row.numOfStandardDraftQuestions }}</span>
                </template>
                <template v-if="column.propName === 'numOfMockExamQuestions'
                    && row.numOfMockExamDraftQuestions > row.numOfMockExamQuestions">
                    <span>{{ row.numOfMockExamQuestions }}</span>
                    <Icon type="arrow" class="exam-draft-list__changes-arrow"></Icon>
                    <span>{{ row.numOfMockExamDraftQuestions }}</span>
                </template>
            </template>
        </List>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import Loading from '@/components/Loading.vue'
import { List, type IListOptions } from '@/components/Lists'
import type { CMS, Study } from '@pocketprep/types'
import examDraftsModule from '@/store/examDrafts/module'
import TitleText from '@/components/TitleText.vue'
import UIKit from '@pocketprep/ui-kit'
import { bundlesModule } from '@/store/bundles/module'
import examsModule from '@/store/exams/module'
import jobsModule from '@/store/jobs/module'
import kaDraftsModule from '@/store/knowledgeAreaDrafts/module'
import questionDraftsModule from '@/store/questionDrafts/module'
import mockExamsModule from '@/store/mockExams/module'
import mockExamDraftsModule from '@/store/mockExamDrafts/module'

interface IMappedExamDraft {
    objectId: string
    nativeAppName: string
    compositeKey: string
    isFree: 'Yes' | 'No'
    descriptiveName: string
    bundle: string
    numOfJobs: number
    numOfSubjects: number
    numOfDraftSubjects: number
    numOfSubtopics: number
    numOfStandardQuestions: number
    hasSubtopics: 'Yes' | 'No'
    numOfMockExamQuestions: number
    numOfMockExamDraftQuestions: number
    hasMockExams: 'Yes' | 'No'
}

@Component({
    components: {
        List,
        Loading,
        TitleText,
        PocketButton: UIKit.Button,
        Icon: UIKit.Icon,
    },
})
export default class ExamDraftList extends Vue {
    isLoading = true
    bundles: string[] = []
    examMetadataIdToBundleMap: { [key: string]: string } = {}
    knowledgeAreaDrafts: CMS.Class.KnowledgeAreaDraftJSON[] = []
    subjects: Study.Class.SubjectJSON[] = []
    questionDrafts: CMS.Class.QuestionDraftJSON[] = []
    mockExams: Study.Class.MockExamJSON[] = []
    mockExamDrafts: CMS.Class.MockExamDraftJSON[] = []

    get exams () {
        return examsModule.state.exams
    }

    get numOfUnarchivedKADraftsLib () {
        return this.knowledgeAreaDrafts.filter(kaDraft => !kaDraft.isArchived).reduce((acc, kaDraft) => {
            const examDraftId = kaDraft.examDraft.objectId
            acc[examDraftId] = (acc[examDraftId] || 0) + 1

            return acc
        }, {} as { [examDraftId: string]: number })
    }

    get numOfCurrSubjectsLib () {
        return this.exams.reduce((acc, exam) => {
            const examId = exam.objectId
            if (exam.knowledgeAreas) {
                acc[examId] = Object.keys(exam.knowledgeAreas).length
            }

            return acc
        }, {} as { [examId: string]: number })
    }

    get numOfCurrMockExamQuestionsLib () {
        return this.exams.reduce((acc, exam) => {
            const examId = exam.objectId
            if (exam.mockExams) {
                const mockExamIds = new Set(exam.mockExams.map(me => me.objectId))
                const examMockExams = this.mockExams.filter(me => mockExamIds.has(me.objectId))
                acc[examId] = examMockExams.reduce((totalSum, me) => totalSum + me.questionSerials.length, 0)
            }

            return acc
        }, {} as { [examId: string]: number })
    }

    get numOfMockExamDraftQuestions () {
        return examDraftsModule.state.examDrafts.reduce((acc, examDraft) => {
            const examDraftId = examDraft.objectId
            if (examDraft.mockExamDrafts) {
                const mockExamDraftIds = new Set(examDraft.mockExamDrafts.map(med => med.objectId))
                const examDraftMockExams = this.mockExamDrafts.filter(med => mockExamDraftIds.has(med.objectId))
                acc[examDraftId] = examDraftMockExams
                    .reduce((totalSum, med) => totalSum + med.questionSerials.length, 0)
            }

            return acc
        }, {} as { [examDraftId: string]: number })
    }

    get numOfDraftSubtopicsLibDraft () {
        return this.knowledgeAreaDrafts.filter(kaDraft => !kaDraft.isArchived).reduce((acc, kaDraft) => {
            const examDraftId = kaDraft.examDraft.objectId
            acc[examDraftId] = (acc[examDraftId] || 0) + (kaDraft.subtopics?.length || 0)

            return acc
        }, {} as { [examDraftId: string]: number })
    }

    get numOfSubtopicsLib () {
        return this.subjects.reduce((acc, subject) => {
            const examId = 'id' in subject.examMetadata
                ? subject.examMetadata.id as string
                : subject.examMetadata.objectId
            acc[examId] = (acc[examId] || 0) + (subject.subtopics?.length || 0)

            return acc
        }, {} as { [examId: string]: number })
    }

    get numOfLiveQuestionsLib () {
        return this.exams.reduce((acc, exam) => {
            const examId = exam.objectId
            acc[examId] = exam.itemCount - exam.archivedCount

            return acc
        }, {} as { [examId: string]: number })
    }

    get numOfNewQuestionDraftsLib () {
        return this.questionDrafts
            .filter(qd => !qd.examDataId && !qd.isMockQuestion && !qd.isArchived)
            .reduce((acc, qd) => {
                const examDraftId = qd.examDraft?.objectId
                if (examDraftId) {
                    acc[examDraftId] = (acc[examDraftId] || 0) + 1
                }

                return acc
            }, {} as { [examDraftId: string]: number })
    }

    get numOfJobsLib () {
        return jobsModule.state.jobs.reduce((acc, job) => {
            const examDraftId = job.examDraftId
            acc[examDraftId] = (acc[examDraftId] || 0) + 1

            return acc
        }, {} as { [examDraftId: string]: number })
    }
    
    get mappedExamDrafts (): IMappedExamDraft[] {
        return examDraftsModule.state.examDrafts.map(examDraft => {
            const examDraftId = examDraft.objectId
            const examDraftMetadataId = examDraft.examMetadataId
            return {
                ...examDraft,
                isFree: examDraft.isFree ? 'Yes' : 'No',
                bundle: examDraftMetadataId && this.examMetadataIdToBundleMap[examDraftMetadataId] || 'No Bundle',
                numOfJobs: this.numOfJobsLib[examDraftId] || 0,
                numOfSubjects: examDraftMetadataId && this.numOfCurrSubjectsLib[examDraftMetadataId] || 0,
                numOfDraftSubjects: this.numOfUnarchivedKADraftsLib[examDraftId] || 0,
                numOfSubtopics: examDraftMetadataId && this.numOfSubtopicsLib[examDraftMetadataId] || 0,
                numOfDraftSubtopics: this.numOfDraftSubtopicsLibDraft[examDraftId] || 0,
                numOfStandardQuestions: examDraftMetadataId && this.numOfLiveQuestionsLib[examDraftMetadataId] || 0,
                numOfStandardDraftQuestions: this.numOfNewQuestionDraftsLib[examDraftId] || 0,
                hasSubtopics: this.numOfDraftSubtopicsLibDraft[examDraftId] ? 'Yes' : 'No',
                numOfMockExamQuestions: examDraftMetadataId
                && this.numOfCurrMockExamQuestionsLib[examDraftMetadataId] || 0,
                numOfMockExamDraftQuestions: this.numOfMockExamDraftQuestions[examDraftId] || 0,
                hasMockExams: ((examDraftMetadataId
                && this.numOfCurrMockExamQuestionsLib[examDraftMetadataId] > 0)
                || this.numOfMockExamDraftQuestions[examDraftId] > 0)
                    ? 'Yes' : 'No',
            }
        })
    }

    get listOptions (): IListOptions<IMappedExamDraft> {
        return {
            listData: this.mappedExamDrafts,
            listSchema: [
                {
                    propName: 'bundle',
                    label: 'Bundle',
                    type: 'text',
                    options: {
                        width: 154,
                    },
                    data: [ 'No Bundle', ...this.bundles ],
                },
                {
                    propName: 'nativeAppName',
                    label: 'Exam',
                    type: 'text',
                    options: {
                        style: 'title',
                        group: 0,
                        width: 180,
                    },
                },
                {
                    propName: 'descriptiveName',
                    label: 'Descriptive Name',
                    type: 'text',
                    options: {
                        width: 241,
                    },
                },
                {
                    propName: 'numOfJobs',
                    label: 'Jobs',
                    type: 'text',
                    options: {
                        width: 95,
                        filter: false,
                        fieldStyles: {
                            textAlign: 'center',
                            paddingLeft: '0',
                            paddingRight: '12px',
                        },
                        labelStyles: {
                            paddingRight: '0',
                        },
                        secondarySortPropName: 'bundle',
                        secondarySortDir: 'ASC',
                        tertiarySortPropName: 'nativeAppName',
                        tertiarySortDir: 'ASC',
                    },
                },
                {
                    propName: 'numOfSubjects',
                    label: 'Subjects',
                    type: 'text',
                    options: {
                        width: 95,
                        filter: false,
                        fieldStyles: {
                            textAlign: 'center',
                            paddingLeft: '0',
                            paddingRight: '12px',
                        },
                        labelStyles: {
                            paddingRight: '0',
                        },
                    },
                },
                {
                    propName: 'numOfSubtopics',
                    label: 'Subtopics',
                    type: 'text',
                    options: {
                        width: 95,
                        filter: false,
                        fieldStyles: {
                            textAlign: 'center',
                            paddingLeft: '0',
                            paddingRight: '12px',
                        },
                        labelStyles: {
                            paddingRight: '0',
                        },
                    },
                },
                {
                    propName: 'numOfStandardQuestions',
                    label: 'SQB Questions',
                    type: 'text',
                    options: {
                        width: 116,
                        filter: false,
                        fieldStyles: {
                            textAlign: 'center',
                            paddingLeft: '0',
                            paddingRight: '12px',
                        },
                        labelStyles: {
                            paddingRight: '0',
                        },
                    },
                },
                {
                    propName: 'numOfMockExamQuestions',
                    label: 'ME Questions',
                    type: 'text',
                    options: {
                        width: 100,
                        filter: false,
                        fieldStyles: {
                            textAlign: 'center',
                            paddingLeft: '0',
                            paddingRight: '12px',
                        },
                        labelStyles: {
                            paddingRight: '0',
                        },
                    },
                },
                {
                    propName: 'compositeKey',
                    label: 'Composite Key',
                    type: 'text',
                    options: {
                        group: 0,
                        width: 144,
                    },
                },
                {
                    propName: 'isFree',
                    label: 'Free Exam',
                    type: 'text',
                    data: [ 'Yes', 'No' ],
                    options: {
                        isHidden: true,
                        filterValueWhenActive: 'Yes',
                    },
                },
                {
                    propName: 'hasSubtopics',
                    label: 'Has Subtopics',
                    type: 'text',
                    data: [ 'Yes', 'No' ],
                    options: {
                        isHidden: true,
                        filterValueWhenActive: 'Yes',
                    },
                },
                {
                    propName: 'hasMockExams',
                    label: 'Has Mock Exams',
                    type: 'text',
                    data: [ 'Yes', 'No' ],
                    options: {
                        isHidden: true,
                        filterValueWhenActive: 'Yes',
                    },
                },
            ],
            defaultSort: {
                propName: 'numOfJobs',
                sortDir: 'ASC',
            },
        }
    }

    examDraftClicked (examDraft: CMS.Class.ExamDraftJSON) {
        this.$router.push({
            name: 'exam-draft-edit',
            params: {
                examDraftId: examDraft.objectId,
            },
        })
    }

    createExamDraftClicked () {
        this.$router.push({
            name: 'exam-draft-create',
        })
    }

    async mounted () {
        this.isLoading = true

        const [ kaDrafts, subjects, bundles, questionDrafts, mockExams, mockExamDrafts ] = await Promise.all([
            kaDraftsModule.actions.fetchKADrafts(),
            examsModule.actions.fetchSubjects(),
            bundlesModule.actions.fetchBundles(),
            questionDraftsModule.actions.fetchQuestionDrafts({ perPage: 0 }),
            mockExamsModule.actions.fetchMockExams(),
            mockExamDraftsModule.actions.fetchAllMockExamDrafts(),
            examDraftsModule.actions.fetchExamDrafts(),
            jobsModule.actions.fetchJobs(),
            examsModule.actions.fetchExams(),
        ])

        this.knowledgeAreaDrafts = kaDrafts
        this.subjects = subjects
        this.bundles = bundles.sort((a, b) => a.name.localeCompare(b.name)).map(b => b.name)
        this.questionDrafts = questionDrafts.results
        this.mockExams = mockExams
        this.mockExamDrafts = mockExamDrafts
        this.examMetadataIdToBundleMap = bundles.reduce((bAcc, b) => {
            const examLib = b.exams.reduce((eAcc, e) => {
                return {
                    [e.objectId]: b.name,
                    ...eAcc,
                }
            }, {})

            return { ...bAcc, ...examLib }
        }, {} as { [key: string]: string })
        
        this.isLoading = false
    }
}
</script>

<style lang="scss" scoped>

.exam-draft-list {
    flex-grow: 1;
    padding: $base;
    padding-bottom: 0;
    margin-top: 52px;

    &__title {
        display: flex;
        align-items: center;
        padding-left: 10px;
        margin-top: 46px;
        margin-bottom: 34px;
    }

    &__empty-bundle-cell {
        padding-left: 10px;
    }

    &__create-draft-btn {
        margin-left: auto;
        margin-right: 10px;
        margin-bottom: 4px;
    }

    &__changes-arrow {
        color: $pewter;
        width: 21px;
    }

    &__filter-type-dropdown-section-title {
        position: absolute;
        top: -24px;
        color: $slate;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
    }
    &__filter-type-dropdown-item-container {
        position: relative;
    }
    :deep(.uikit-select__item[data-value="Bundle"]),
    :deep(.uikit-select__item[data-value="Free Exam"]) {
        margin-top: 31px;
    }
}
</style>