<template>
    <div id="app" class="app">
        <RouterView />
        <Toast
            v-if="toast"
            class="app__toast"
            :right="true"
            @close="clearToast"
        >
            {{ toast.title }}
            <template v-if="toast.subtext" #subtext>
                {{ toast.subtext }}
            </template>
        </Toast>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { toastModule } from '@/store/toast/module'
import UIKit from '@pocketprep/ui-kit'

// Toasts
const Toast = UIKit.Toast
const toast = computed(() => toastModule.state.toast)
const clearToast = () => {
    toastModule.actions.clearToast()
}

</script>

<style lang="scss" scoped>
.app {
    &__toast {
        bottom: 73px;
    }
}
</style>
